import { Injectable } from '@angular/core';
import {
	FinancialsFiscalYear,
	FinancialsPeriod,
	ResourceParams,
	FinancialsPeriodsResource,
} from '@towncloud/thor-api';
@Injectable({
  providedIn: 'root',
})
export class FinancialsCurrentFiscalYearService {
	currentFiscalYear = new FinancialsFiscalYear();
	currentPeriod = new FinancialsPeriod();
	currentEffectiveDate = '';
	startOfEffectiveMonth = '';
	constructor(private periodsResource: FinancialsPeriodsResource) {}

	setFiscalYear(year) {
		if (this.currentFiscalYear != year) {
			this.currentFiscalYear = year;
			sessionStorage.setItem('asOfFiscalYear', JSON.stringify(this.currentFiscalYear));
			// console.log('set year');
		}
	}

	setPeriod(period) {
		if (this.currentPeriod != period) {
			this.currentPeriod = period;
			sessionStorage.setItem('asOfPeriod', JSON.stringify(this.currentPeriod));
			this.setEffectiveDate(this.currentPeriod.endDate);
		}
	}

	getFiscalYear() {
		if (this.currentFiscalYear && this.currentFiscalYear.id) {
			return this.currentFiscalYear;
		} else {
			if (sessionStorage.getItem('asOfFiscalYear')) {
				this.currentFiscalYear = JSON.parse(sessionStorage.getItem('asOfFiscalYear'));
				return this.currentFiscalYear;
			}
		}
	}

	getPeriod() {
		if (this.currentPeriod && this.currentPeriod.id) {
			return this.currentPeriod;
		} else {
			if (sessionStorage.getItem('asOfPeriod')) {
				this.currentPeriod = JSON.parse(sessionStorage.getItem('asOfPeriod'));
				return this.currentPeriod;
			}
		}
	}

	getPeriodForDate() {
		const params = new ResourceParams({
			expand: ['fiscalYear'],
			filters: [
				[
					{
						property: 'startDate',
						operator: 'lte',
						value: this.currentEffectiveDate,
					},
					{
						property: 'endDate',
						operator: 'gte',
						value: this.currentEffectiveDate,
					},
				],
			],
		});

		this.periodsResource.get(params).then(data => {
			this.currentPeriod = data.items[0];
			sessionStorage.setItem('asOfPeriod', JSON.stringify(this.currentPeriod));
		});
	}

	setEffectiveDate(asOfDate) {
		if (this.currentEffectiveDate != asOfDate) {
			this.currentEffectiveDate = asOfDate;
			sessionStorage.setItem('asOfDate', JSON.stringify(this.currentEffectiveDate));
			this.startOfEffectiveMonth = this.setStartOfEffectiveMonth();
			this.getPeriodForDate();
			// console.log('set date');
		}
	}

	getEffectiveDate() {
		if (this.currentEffectiveDate) {
			return this.currentEffectiveDate;
		} else {
			if (sessionStorage.getItem('asOfDate')) {
				this.currentEffectiveDate = JSON.parse(sessionStorage.getItem('asOfDate'));
				this.startOfEffectiveMonth = this.setStartOfEffectiveMonth();
				return this.currentEffectiveDate;
			}
		}
	}

	getStartOfEffectiveMonth() {
		if (this.currentEffectiveDate) {
			return this.startOfEffectiveMonth;
		}
	}

	private setStartOfEffectiveMonth() {
		const effYear = this.currentEffectiveDate.substring(0,4);
		const effMonth = this.currentEffectiveDate.substring(5,7);
		const effDay = '01';

		this.startOfEffectiveMonth = effYear + '-' + effMonth + '-' + effDay;
		return this.startOfEffectiveMonth;
	}


}
