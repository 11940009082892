const getAmount = (value) => {
	return Number(value.replace(/[^0-9.-]+/g,""));
};

export class MonetaryAmount {
	number: string;
	prefix: string;
	amount = 0;

	constructor(value, prefix) {
		this.number = value;
		this.prefix = prefix;
	}

	get() {
		return getAmount(this.number);
	}

	format() {
		if (!this.number) return;
		const thousand_separator = ',',
			decimal_separator = '.',
			regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
			number_string = this.number.replace(regex, '').toString(),
			split = number_string.split(decimal_separator),
			rest = split[0].length % 3,
			thousands = split[0].substring(rest).match(/\d{3}/g);

		let	result = split[0].substring(0, rest);
	
		if (thousands) {
			const separator = rest ? thousand_separator : '';
			result += separator + thousands.join(thousand_separator);
		}
		
		result = split[1] != undefined ? result + decimal_separator + split[1] : result;
		return this.prefix == undefined ? result : (result ? this.prefix + result : '');
	}
  
}