import { Component } from '@angular/core';

@Component({
  templateUrl:  './home.html'
})

export class HomeComponent { 


	constructor(){}

}