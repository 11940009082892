import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CurrentTownService } from '../services/currentTown.service';
import { HammerApiService } from '../services/hammerApi.service';

import { HammerResourceService } from '../services/hammerResource.service';
import { environment } from '../environments/environment';

@Injectable()
export class PasswordResource extends HammerResourceService {
	public ApiUrl: string = '/';
	public ServerWithApiUrl = environment.candygramHostName + '/';

	private actionUrl: string;

	url = 'password';

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService,
		hammerApiService: HammerApiService
	) {
		super(hammerApiService);
	}

}
