import { Pipe } from '@angular/core';

import * as moment from 'moment';

@Pipe({
    name: 'gridColumnTotalRowFormatPipe'
})
export class GridColumnTotalRowFormatPipe {

    transform(item, args): any {

        if (args.format) {
            switch (args.format) {
                case 'currency':
                    if (item) {
                        // return '$' + parseFloat(item).toFixed(2);
                        let formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          });
                          return formatter.format(item);
                    }
                    return item;
                default:
                    return item;
            }
        }

        return item;
    }
}