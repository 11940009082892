import { BudgetChartComponent } from './../financials/generalLedger/charts/budgetChart/budgetChart.component';
import { BudgetLineChartComponent } from '../financials/generalLedger/charts/budgetLineChart/budgetLineChart.component';
import { ActualsBarChartComponent } from '../financials/generalLedger/charts/actualsBarChart/actualsBarChart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// import { JsonpModule } from "@angular/http";
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './globalErrorHandler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExpiredTokenInterceptor } from './expiredToken.interceptor';
import { ChartsModule } from 'ng2-charts';
import {
	MockDataService,
	HammerApiService,
	MenuService,
	CurrentUserService,
	// CurrentTownService,
	FormService,
	// PermissionsRouteGuard,
	LoginService,
	GridService,
	UsersGrid,
	AttendeeRolesGrid,
} from '../../services/services';

import {
	// CurrentUserResource,
	GridSettingsResource,
	CitySettingsResource,
	CityLogoResource,
	DownloadResource,
	PasswordResource,
	MenuResource,
	$UsersResource,
	$RolesResource,
	LicenseRenewalLettersResource,
	InfoRequestResource,
} from '../../resources/resources';

import { FieldMask } from './pipes/fieldMask.pipe';
import { Filter } from './pipes/filter.pipe';

import { ViewComponent } from './components/view/view.component';
import { MainMenuComponent } from '../app/components/mainMenu/mainMenu.component';
import { SubMenuComponent } from '../app/components/mainMenu/submenu/subMenu.component';
import { MainMenuPipe } from '../app/components/mainMenu/mainMenu.filter';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { TableComponent } from './components/table/table.component';
import { DynamicHTMLComponent } from './directives/dynamicHTML.directive';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { WizardComponent } from './components/wizard/wizard.component';
import { WizardStepComponent } from './components/wizard/wizardStep.component';
import { ModalComponent } from './components/modal/modal.component';
import { FieldComponent } from './components/field/field.component';
import { FieldCalendarComponent } from './components/field/calendar/calendar.component';
import { GridComponent } from './components/grid/grid.component';
import { GridColumnFormatPipe } from './components/grid/gridColumnFormat.pipe';
import { GridColumnTotalRowFormatPipe } from './components/grid/gridColumnTotalRowFormat.pipe';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { ButtonListComponent } from './components/buttonList/buttonList.component';
import { AddressComponent } from './components/address/address.component';

import { CreateContactFormComponent } from './components/createContactForm/createContactForm.component';

import { SortableContainerComponent } from './components/sortable/sortableContainer.component';
import { SortableComponent } from './components/sortable/sortable.component';

import { IconSpriteComponent } from './components/icon/iconSprite.component';
import { IconComponent } from './components/icon/icon.component';

import { LicenseRenewalLetterComponent } from '../utilities/businesses/detail/licenses/renewal/letter/licenseRenewalLetter.component';

import { SandboxComponent } from './views/sandbox/sandbox.component';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgxWigModule } from 'ngx-wig';
import { CKEditorModule } from 'ng2-ckeditor';
import { NewBusinessModalComponent } from '../utilities/businesses/newModal/newBusinessModal.component';
import { NewContactModalComponent } from '../utilities/contacts/newModal/newContactModal.component';
// import { CurrentUserResource } from '../../resources/currentUser.resource';


@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		// JsonpModule,
		FormsModule,
		ChartsModule,
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		NgxWigModule,
		CKEditorModule,

		RouterModule.forChild([
			{
				path: 'sandbox',
				component: SandboxComponent,
				data: {
					name: 'sandbox',
					breadCrumbs: [
						{ name: 'Home', path: '' },
						{ name: 'Sandbox', path: 'sanbox' },
					],
				},
			},
		]),
	],
	providers: [
		MockDataService,
		// CurrentTownService,
		CurrentUserService,
		LoginService,

		GridService,
		UsersGrid,
		AttendeeRolesGrid,

		HammerApiService,
		MenuService,
		FormService,
		// PermissionsRouteGuard,

		// CurrentUserResource,

		GridSettingsResource,
		DownloadResource,
		PasswordResource,
		CitySettingsResource,
		CityLogoResource,
		MenuResource,
		$UsersResource,
		$RolesResource,
		LicenseRenewalLettersResource,
		InfoRequestResource,
		{
			// processes all errors
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{
            provide: HTTP_INTERCEPTORS,
            useClass: ExpiredTokenInterceptor,
            multi: true
        }
	],
	declarations: [
		ViewComponent,
		MainMenuComponent,
		SubMenuComponent,
		MainMenuPipe,
		BreadcrumbsComponent,
		TableComponent,
		DynamicHTMLComponent,
		ClickOutsideDirective,
		WizardComponent,
		WizardStepComponent,
		ModalComponent,
		FieldComponent,
		FieldCalendarComponent,
		GridComponent,
		GridColumnFormatPipe,
		GridColumnTotalRowFormatPipe,
		TabsComponent,
		TabComponent,
		ButtonListComponent,
		AddressComponent,

		CreateContactFormComponent,

		IconSpriteComponent,
		IconComponent,

		SortableContainerComponent,
		SortableComponent,

		LicenseRenewalLetterComponent,

		SandboxComponent,

		FieldMask,
		Filter,
		BudgetChartComponent,
		BudgetLineChartComponent,
		ActualsBarChartComponent,

		NewBusinessModalComponent,
		NewContactModalComponent
	
	],
	exports: [
		ViewComponent,
		MainMenuComponent,
		MainMenuPipe,
		BreadcrumbsComponent,
		TableComponent,
		DynamicHTMLComponent,
		ClickOutsideDirective,
		WizardComponent,
		ModalComponent,
		WizardStepComponent,
		FieldComponent,
		FieldCalendarComponent,
		GridComponent,
		GridColumnFormatPipe,
		GridColumnTotalRowFormatPipe,
		TabsComponent,
		TabComponent,
		ButtonListComponent,
		AddressComponent,

		CreateContactFormComponent,

		IconSpriteComponent,
		IconComponent,

		SortableContainerComponent,
		SortableComponent,

		LicenseRenewalLetterComponent,

		Filter,

		BudgetChartComponent,
		BudgetLineChartComponent,
		ActualsBarChartComponent,
		NewBusinessModalComponent,
		NewContactModalComponent
	],
})
export class CoreModule { }
