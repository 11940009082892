import { Component, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'my-modal',
  templateUrl:  './modal.template.html',
  styleUrls: [ './modal.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalComponent { 

	@Output() close: EventEmitter<any> = new EventEmitter();
	@Output() print: EventEmitter<any> = new EventEmitter();
	
	@Input() show: boolean;
	@Input() printable: boolean;
	@Input() type: '' | 'warning' = '';
	@Input() width: '' | 'half' = '';
	@Output() showChange: EventEmitter<any> = new EventEmitter();

	constructor(
		private elementRef: ElementRef
	){}

	closeModal(){
		this.show = false;
		
		this.close.emit();
		this.showChange.emit(false);
	}

	printScreen(){

		this.print.emit();

		const head = document.getElementsByTagName("head")[0];
		const html = this.elementRef.nativeElement.querySelector('[body]').innerHTML;

		const mywindow = window.open('');
		mywindow.document.write('<html><head>' + head.innerHTML + '</head><body>' + html + "</body></html>" );

		mywindow.document.close();
		mywindow.focus();

		setTimeout( () => {
			mywindow.print();
			mywindow.close();
		}, 100)
	}

}