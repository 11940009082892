import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Field, Grid } from '../../../../dataTypes/dataTypes';

import {
	CountriesResource,
	StatesResource,

	ResourceParams,
	Country,
	State
} from '@towncloud/thor-api';

import { CurrentTownService } from '../../../../services/services';

@Component({
	selector: 'tc-address',
	templateUrl: './address.html',
	styleUrls: ['./address.scss']
})

export class AddressComponent {

	private _settings: any = {};
	@Input()
	settings: any = {};

	@Input()
	type = 'local';

	@Input()
	threeLines = false;

	@Input()
	leftLabels = false;

	@Input()
	enableDefaultValues = true;

	@Input()
	required: boolean;

	@Input()
	disabled: boolean;

	@Input()
	isValid = true;
	
	@Output() isValidChange: EventEmitter<any> = new EventEmitter();

	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();

	address1: Field;
	address2: Field;
	address3: Field;
	city: Field;
	state: Field;
	stateName: Field;
	zipCode: Field;
	country: Field;

	physicalAddress;
	hasStates = false;

	gettingCountries;
	gettingsStates;

	currentTown: any;

	constructor(
		private countriesResource: CountriesResource,
		private statesResource: StatesResource,
		private currentTownService: CurrentTownService,
	) {

		this.country = new Field({
			type: 'lookup',
			// label: 'Country',
			grid: new Grid,
			lookupLabel: 'name',
			required: false
		});

		this.country.grid.resource = this.countriesResource;
		this.country.grid.resourceParams = new ResourceParams;

		this.country.grid.addColumn({
			label: 'Name',
			field: 'name',
			sortOrder: 'ASC'
		});

		this.country.grid.addFilter({
			filterable: true,
			property: 'name',
			operator: 'contains',
			field: new Field({
				type: 'input',
				label: 'Name'
			})
		});


		this.address1 = new Field({
			type: 'input',
			// label: 'Address 1'
		});

		this.address2 = new Field({
			type: 'input',
			// label: 'Address 2'
		});

		this.address3 = new Field({
			type: 'input',
			// label: 'Address 3'
		});

		this.city = new Field({
			type: 'input',
			// label: 'City'
		});


		this.state = new Field({
			type: 'lookup',
			// label: 'State',
			grid: new Grid,
			lookupLabel: 'name',
			required: false
		});

		this.state.grid.resource = this.statesResource;
		this.state.grid.resourceParams = new ResourceParams;

		this.state.grid.addColumn({
			label: 'Name',
			field: 'name',
			sortOrder: 'ASC'
		});

		this.state.grid.addFilter({
			filterable: true,
			property: 'name',
			operator: 'contains',
			field: new Field({
				type: 'input',
				label: 'Name'
			})
		});


		this.stateName = new Field({
			type: 'input',
			// label: 'State Name'
		});

		this.zipCode = new Field({
			type: 'input',
			// label: 'Zip Code'
		});
	}

	ngOnChanges(changes) {
		// console.log('* * * * * * ngOnChanges * * * * * *');
		if (!this._settings) {
			this._settings = Object.create(this.settings);
		}

		this.applySettings();


		this.country.disabled = this.disabled;
		this.address1.disabled = this.disabled;
		this.address2.disabled = this.disabled;
		this.address3.disabled = this.disabled;
		this.city.disabled = this.disabled;
		this.state.disabled = this.disabled;
		this.stateName.disabled = this.disabled;
		this.zipCode.disabled = this.disabled;

		setTimeout(() => {
			this.setIsValid();
		});
	}

	ngDoCheck() {
		// console.log('* * * * * * ngDoCheck * * * * * *');
		if (
			this._settings.country !== this.settings.country ||
			this._settings.address1 !== this.settings.address1 ||
			this._settings.address2 !== this.settings.address2 ||
			this._settings.address3 !== this.settings.address3 ||
			this._settings.city !== this.settings.city ||
			this._settings.state !== this.settings.state ||
			this._settings.stateName !== this.settings.stateName ||
			this._settings.zipCode !== this.settings.zipCode
		) {

			this._settings = Object.create(this.settings);

			this.applySettings();

			setTimeout(() => {
				this.setIsValid();
			});
		}
		// this.setIsValid();
	}

	async ngOnInit() {
		// console.log('* * * * * * ngOnInit * * * * * *');
		if (!this.leftLabels) {
			this.country.label = "Country";
			this.address1.label = "Address 1";
			this.address2.label = "Address 2";
			this.address3.label = "Address 3";
			this.city.label = "City";
			this.state.label = "State";
			this.stateName.label = "State name";
			this.zipCode.label = "Zip code";
		}

		this.country.required = this.required;
		this.address1.required = this.required;
		this.city.required = this.required;
		this.state.required = this.required;
		this.stateName.required = this.required;
		this.country.required = this.required;
		this.zipCode.required = this.required;

		this.currentTownService.get().then(city => {
			this.currentTown = city;

			this.applySettings();
			// this.setIsValid();
		})
	}

	applySettings() {

		this._settings.address1 = this.settings.address1;
		this._settings.address2 = this.settings.address2;
		this._settings.address3 = this.settings.address3;
		this._settings.city = this.settings.city;
		this._settings.stateName = this.settings.stateName;
		this._settings.zipCode = this.settings.zipCode;

		if (this.settings.country) {

			if (this._settings.country !== this.settings.country) {

				// if(this.gettingCountries == undefined) {

				const params = new ResourceParams({
					filters: [
						[
							{
								property: 'id',
								operator: 'eq',
								value: this.settings.country
							}
						]
					]
				});

				this.countriesResource.get(params).then((country) => {

					if (this.settings.country === country.items[0].id) {
						this.country.value = country.items[0];
						this._settings.country = this.settings.country;

						this.applyCountrySettings();
					}

				});
				// }
			}

		} else {

			// if(this.gettingCountries == undefined) {

			const params = new ResourceParams({
				filters: [
					[
						{
							property: 'name',
							operator: 'contains',
							value: 'United States of America'
						}
					]
				]
			});

			this.countriesResource.get(params).then((country) => {

				// if (!this.settings.country) {
				this.country.value = country.items[0];
				this.settings.country = this.country.value.id;
				this._settings.country = this.settings.country;


				this.applyCountrySettings();
				// }

			});
			// }
		}

		this.address1.value = this.settings.address1;
		this.address2.value = this.settings.address2;
		this.address3.value = this.settings.address3;
		this.city.value = this.settings.city;
		this.stateName.value = this.settings.stateName;

		if (this.settings.state) {

			// if(this._settings.state != this.settings.state && this.gettingsStates == undefined) {
			let params = new ResourceParams({
				filters: [
					[
						{
							property: 'id',
							operator: 'eq',
							value: this.settings.state
						}
					]
				]
			});

			this.statesResource.get(params).then((state) => {
				this.state.value = state.items[0];
			});
			// }

		} else {
			this.state.value = undefined;
		}
		this._settings.state = this.settings.state;

		this.zipCode.value = this.settings.zipCode;
		
		if (this.required && this.enableDefaultValues==true) {
			if (!this.city.value && this.currentTown) {
				this.city.value = this.currentTown.address.city;
				this.settings.city = this.city.value;
			}

			if (!this.state.value && this.currentTown) {
				this.state.value = this.currentTown.address.state;
				this.settings.state = this.state.value.id;
			}
		}
		// console.log(this.isValid);
	}

	getCountries() {

		return new Promise((resolve, reject) => {

			let params = new ResourceParams({
				// $all: true
			});

			this.countriesResource.get(params).then(countries => {
				resolve(countries.items);
			});
		});

	}

	setStatesResourceParams() {

		this.state.grid.resourceParams = new ResourceParams({
			filters: [
				[
					{
						property: 'countryId',
						operator: 'eq',
						value: this.settings.country
					}
				]
			]
		});
	}

	fieldChanged(field) {
		// console.log('# # # # # # field changed - ', this[field]);
		if (this[field].required) {
			if (this[field].value == undefined || this[field].value == null  || this[field].value == "") {
				this[field].invalid = true;
			} else {
				this[field].invalid = false;
			}
		}

		if (field === 'country') {

			this.settings[field] = this[field].value.id;

			this.settings.state = undefined;
			this.settings.stateName = undefined;

			this.applyCountrySettings();

		} else if (field === 'state') {

			this.settings[field] = this[field].value.id;

		} else {

			this.settings[field] = this[field].value;

		}

		this.setIsValid();
		// console.log('* * * * * * emit modelChange * * * * * *');
		this.ngModelChange.emit(field);
	}

	setIsValid() {

		this.isValid = true;
		if (this.required) {

			if (!this.settings.address1 ||
				!this.settings.city ||
				!this.settings.zipCode ||
				(!this.settings.country && this.type === 'international')) {

				this.isValid = false;
			} else if (!this.settings.state && !this.settings.stateName) {
				
				this.isValid = false;
			}

		}
		// console.log('* * * * * * emit isValidChange * * * * * *', this.isValid);
		this.isValidChange.emit(this.isValid);
	}

	applyCountrySettings() {

		let params = new ResourceParams({
			limit: 1,
			filters: [
				[
					{
						property: 'countryId',
						operator: 'eq',
						value: this.settings.country
					}
				]
			]
		});

		this.statesResource.get(params).then(states => {

			if (states.page.totalCount > 0) {
				this.state.required = this.required;
				this.stateName.required = false;

				this.hasStates = true;
			} else {
				this.state.required = false;
				this.stateName.required = this.required;

				this.hasStates = false;
			}


			this.setStatesResourceParams();

		});


	}

}
