import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuResource } from '../resources/menu.resource';
import { ResourceParams } from '@towncloud/thor-api';
import { CurrentTownService } from './currentTown.service';
export let MAINMENU = [];

@Injectable()
export class MenuService implements OnInit{
	private _mainMenu = new BehaviorSubject<any>(MAINMENU);
	mainMenu = this._mainMenu.asObservable();

	constructor(
		private currentTownService: CurrentTownService,
		private menuResource: MenuResource) {}

	private static compare(a, b) {
		if (a.position < b.position) {
			return -1;
		}
		if (a.position > b.position) {
			return 1;
		}
		return 0;
	}

	async ngOnInit() {
		MAINMENU = await this.getMenu();
	}

	async getMain() {
		return await this.getMenu();
	}

	setPathlinks(item) {
		if (item.path) {
			const townSlug = this.currentTownService.getTown();
			const entity = JSON.parse(sessionStorage.getItem('entity'));
			const suite = JSON.parse(sessionStorage.getItem('suite'));
			let updatedPath = item.path;
	
			if (entity) {
				updatedPath = updatedPath.replace(/:entityId/g, entity.id);
			}
		
			if (suite) {
				updatedPath = updatedPath.replace(/:suite/g, suite);
				// updatedPath = updatedPath.replace(/Revenue/g, suite);
				// updatedPath = updatedPath.replace(/Finance/g, suite);
			}
		
			if (updatedPath.includes(':town?')) {
				if (townSlug) {
					return updatedPath.replace(':town?', townSlug);
				} else {
					return updatedPath.replace(':town?/', '');
				}
			} else {
				if (townSlug) {
					return updatedPath.replace(/:town/g, townSlug);
				} else {
					return updatedPath.replace(/:town/g, '');
				}
			}
		} else {
			return '';
		}

	}

	private async getMenu() {
		if (MAINMENU.length > 0) {
			return MAINMENU;
		}

		const data = new ResourceParams({
			$all: true,
		});

		const menu = (await this.menuResource.get(data)).items;

		MAINMENU = this.colapse(this.sort(this.buildMenuObject(menu)));

		return MAINMENU;
	}

	private buildMenuObject(menu) {
		const obj = [];

		menu.forEach((m) => {
			if (!m.parent_menu_id) {
				m.list = this.buildMenuItemList(menu, m._id);
				obj.push(m);
			}
		});

		return obj;
	}

	private buildMenuItemList(menu, id) {
		const list = [];

		menu.forEach((m) => {
			if (m.parent_menu_id === id) {
				m.list = this.buildMenuItemList(menu, m._id);
				list.push(m);
			}
		});

		return list.length > 0 ? list : undefined;
	}

	private colapse(menu) {
		menu.forEach((item) => {
			item.expand = false;
			if (item.list) {
				item.list = this.colapse(item.list);
			}
		});

		return menu;
	}

	private sort(menu) {
		menu.sort((a, b) => {
			const labelA = a.label.toUpperCase();
			const labelB = b.label.toUpperCase();
			let comparision = -1;
			if (labelA > labelB) {
				comparision = 1;
			}
			return comparision;
		});

		menu.forEach((item) => {
			if (item.list) {
				item.list = this.sort(item.list);
			}
		});

		return menu;
	}
}
