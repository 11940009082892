import { Component, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Field } from '../../../../dataTypes/dataTypes';

import { 
	ContactsResource,
	ContactTypesResource,
	StatesResource,
	CountriesResource,

	ResourceParams, 
	Address
} from '@towncloud/thor-api';

import { FormService } from '../../../../services/services';

import { WizardComponent } from '../wizard/wizard.component';

@Component({
	selector: 'createContactForm',
  	templateUrl: './createContactForm.html',
  	encapsulation: ViewEncapsulation.None
})

export class CreateContactFormComponent { 


	showCreateContactModal: boolean = false;
	stepOneFields: any = {};
	stepTwoFields: any = {};

	address: any = {};
	addressIsValid: boolean;

	Wizard: any;

	@ViewChild(WizardComponent) wizard:WizardComponent;

	@Output()created: EventEmitter<any> = new EventEmitter();

	constructor(
		private contactsResource: ContactsResource,
		private contactTypesResource: ContactTypesResource,
		private formService: FormService
	){	
		this.Wizard = {};

		this.buildStepOneFields();
		this.buildStepTwoFields();
	}


	buildStepOneFields(){

		this.stepOneFields.title = new Field({
			type: 'input',
			label: 'Title'
		})

		this.stepOneFields.name = new Field({
			type: 'input',
			label: 'Name',
			required: true
		})

		this.stepOneFields.preferredName = new Field({
			type: 'input',
			label: 'Preferred Name'
		})
	}

	buildStepTwoFields(){
		this.stepTwoFields.homePhone = new Field({
			type: 'input',
			label: 'Home Phone',
			required: false
		})

		this.stepTwoFields.cellPhone = new Field({
			type: 'input',
			label: 'Cell Phone',
			required: false
		})

		this.stepTwoFields.workPhone = new Field({
			type: 'input',
			label: 'Work Phone',
			required: false
		})

		this.stepTwoFields.fax = new Field({
			type: 'input',
			label: 'Fax'
		})

		this.stepTwoFields.email = new Field({
			type: 'input',
			label: 'Email',
			required: false
		})
	}

	isStepOneValid(){
		return this.formService.isRequiredFieldsValid(this.stepOneFields);
	}

	isStepTwoValid(){
		return this.formService.isRequiredFieldsValid(this.stepTwoFields);
	}

	isStepThreeValid(){
		return this.addressIsValid;
	}

	create(){

		var params = {
			title: this.stepOneFields.title.value,
			name: this.stepOneFields.name.value,
			preferredName: this.stepOneFields.preferredName.value,
			
			homePhone: this.stepTwoFields.homePhone.value,
			cellPhone: this.stepTwoFields.cellPhone.value,
			workPhone: this.stepTwoFields.workPhone.value,
			fax: this.stepTwoFields.fax.value,
			email: this.stepTwoFields.email.value,

			address: new Address({
				address1: this.address.address1,
				address2: this.address.address2,
				city: this.address.city,
				stateId: this.address.state,
				stateName: this.address.stateName,
				zipCode: this.address.zipCode,
				countryId: this.address.country,
			})
		}

		this.contactsResource.create( params ).then( data => {
			this.created.emit( data );

			this.reset();
		})
	}

	reset(){

		for(var param in this.stepOneFields){
			this.stepOneFields[param].value = undefined;
		}

		for(var param in this.stepTwoFields){
			this.stepTwoFields[param].value = undefined;
		}

		this.address = {};

		this.wizard.changeStep(1);
	}
}
