import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Field } from '../../../../dataTypes/dataTypes';
import { FormService } from '../../../../services/services';
import {
	ContactsResource,
	// Address,
	Contact
} from '@towncloud/thor-api';

@Component({
	selector: 'tc-contact',
	templateUrl: './newContactModal.html',
	styleUrls: [ './newContactModal.scss'],
})

export class NewContactModalComponent implements OnInit{
	@Input() defaults: any = {};
	@Input() newContact = undefined;
	@Output() newContactChange: EventEmitter<any> = new EventEmitter();

	contact: Contact;
	fields: any = {};
	address: any = {};
	disableMailingAddress = false;
	isValid = false;
	addressIsValid: boolean;

	constructor(
		public contactsResource: ContactsResource,
		private formService: FormService,
	) {

		this.fields.name = new Field({
			type: 'input',
			label: 'Contact Name',
			required: true
		});
		
	}

	ngOnInit() {
		this.applyDefaults();
	}

	applyDefaults() {
		// console.log(this.defaults);
		this.fields.name.value = this.defaults.name;
		this.address.address1 = this.defaults.address1;
		this.address.address2 = this.defaults.address2;
		this.address.city = this.defaults.city;
		this.address.zipCode = this.defaults.zipCode;
		this.address.state = this.defaults.stateId;
		this.address.stateName = this.defaults.stateName;
		this.address.country = this.defaults.countryId;
		this.fieldChanged();
	}

	fieldChanged() {
		if (this.formService.isRequiredFieldsValid(this.fields)) {
			this.isFormValid();
		}
		
	}

	isFormValid() {
		this.isValid = this.addressIsValid;
	}
	
	createContact() {
		const params = {
			'name': this.fields.name.value,
			'address': {
			'address1': this.address.address1,
			'address2': this.address.address2,
			'city': this.address.city,
			'stateId': this.address.state,
			'stateName': this.address.stateName,
			'zipCode': this.address.zipCode,
			'countryId': this.address.country,
			},
		};

		this.contactsResource.create( params ).then( data => {
			this.contact = data;
			this.newContactChange.emit(this.contact);
		});
	}

}
