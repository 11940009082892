import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Grid, Field } from '../../../../dataTypes/dataTypes';

import {
	ResourceParams
} from '@towncloud/thor-api';

@Component({
  templateUrl: './sandbox.html'
})

export class SandboxComponent {

	ckeConfig: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute
	) {
	}

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true
		};
	}
}
