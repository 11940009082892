import { Component } from '@angular/core';

@Component({
	templateUrl: './404.html',
})

export class NoFoundComponent { 

	constructor(){}

}