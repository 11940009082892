import { Component, Input, ViewChild, ElementRef } from '@angular/core';
// import { ChartOptions } from 'chart.js';

@Component({
  selector: 'budgetLineChart',
  templateUrl: './budgetLineChart.component.html',
  styleUrls: ['./budgetLineChart.component.css']
})
export class BudgetLineChartComponent {
  @ViewChild('lineChart', {static: false}) myCanvas: ElementRef;
  @Input() lineChartData: any;
  @Input() lineChartLabels: any;
  @Input() lineChartTitle: string;
 
  // public lineChartOptions: ChartOptions = {
  //   title: {
  //     display: true,
  //     text: 'World population per region (in millions)'
  //   }
  // }

  
  public lineChartType = "'line'";
  public lineChartLegend = true;
  public context: any;

  constructor() {}

}
