"use strict";

const config = [
	{
		issuer: "US phone number",
		patterns: [{ length: 10, mask: [3, 3, 4] }],
	},
	
];

const getPhoneNumber = (number) => {
	return config[0];
};

const getPatter = (number) => {
	const ph = getPhoneNumber(number);
	const pattern = { mask: [3, 3, 4] };

	if (!ph) return pattern;

	const patterns = ph.patterns;
	for (let j = 0; j < patterns.length; j++) {
		if (number.length <= patterns[j].length) {
			return patterns[j];
		}
	}

	return pattern;
};

export class PhoneNumber {
	number: string;
	ph: any;
	pattern: any;

	constructor(value) {
		this.number = value;
		this.ph = getPhoneNumber(value);
	}

	get() {
		return this.ph;
	}

	format() {
		if (!this.number) return;
		//  regular expression /\D/g matches all non-digits
		let phoneNumber = this.number.toString().replace(/\D/g, "");
		const pattern = getPatter(phoneNumber);

		if (!pattern) return this.number;

		const mask = pattern.mask;
		let ret = ['('];

		if (Array.isArray(mask)) {
			let maskPosition = 0;
		
;			for (let i = 0; i < mask.length; i++) {	
				const val = phoneNumber.substring(maskPosition, maskPosition+mask[i]);
				if (val != "")  {
					ret.push(val);
				}

				if (ret.length == 2) {
					ret.push(') ');
				}
				if (ret.length == 4) {
					ret.push('-');
				}
				maskPosition += mask[i];
			}
		}

		return ret.join(" ");
	}
}
