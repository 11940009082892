import { Component, Input, HostListener, ElementRef, Host } from '@angular/core';

import { SortableContainerComponent } from './sortableContainer.component';

@Component({
  selector: '[sortable]',
  host: { "draggable": "true" },
  template: '<ng-content></ng-content>'
})

export class SortableComponent { 

	@HostListener('dragstart', ['$event']) onDragStart(e){

		var hasClass = false;
		this.el.nativeElement.classList.forEach( function(value){

			if(value == 'dragging'){
				hasClass = true;
			}
		})

		if(!hasClass){
			this.el.nativeElement.className += " dragging";
		}

		this.container.dragStart(this.el.nativeElement, e);
	}

	@HostListener('dragenter', ['$event']) onDragEnter(e){
		this.container.dragEnter(this.el.nativeElement, e);
	}

	@HostListener('dragend', ['$event']) onDragEnd(e){

		var reg = new RegExp('(\\s|^)dragging(\\s|$)');

		this.el.nativeElement.className = this.el.nativeElement.className.replace(reg, ' ');

		this.container.dragEnd(this.el.nativeElement, e);
	}

	private _sortable: any;

	@Input()
	set sortable(Object: any){
		this._sortable = Object;
	}
	get sortable(): any {
		return this._sortable;
	}

	container: SortableContainerComponent;
	el: ElementRef;

	constructor(
		container: SortableContainerComponent,
		el: ElementRef ) {

		this.container = container;
		this.el = el;

	}
}