import { Component, forwardRef, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { ComponentBase } from '../componentBase';

import { Field } from '../../../../dataTypes/dataTypes';

@Component({
	selector: 'buttonList',
	templateUrl: './buttonList.html',
	styleUrls: ['./buttonList.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ButtonListComponent extends ComponentBase {

	@Input()
	label: string;

	@Input()
	items: Array<string> = [];

	@Output()selected: EventEmitter<any> = new EventEmitter();

	showButtonGroupItems: boolean = false;
	constructor() {
		super();
	}

	buttonGroupToggle() {
		this.showButtonGroupItems = !this.showButtonGroupItems;
	}

	selectItem(item) {
		this.selected.emit(item);

		this.closeButtonGroup();
	}

	closeButtonGroup() {
		this.showButtonGroupItems = false;
	}

}