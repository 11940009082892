import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CurrentTownService } from '../services/currentTown.service';
import { HammerApiService } from '../services/hammerApi.service';

import { HammerResourceService } from '../services/hammerResource.service';
import { environment } from '../environments/environment';

@Injectable()
export class LicenseRenewalLettersResource extends HammerResourceService {
	public ApiUrl: string = '/';
	public ServerWithApiUrl = environment.candygramHostName + '/';

	private actionUrl: string;

	url = 'licenseRenewalLetter';

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService,
		hammerApiService: HammerApiService
	) {
		super(hammerApiService);
	}

}
