import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'my-app',
  templateUrl: './app.html',
  styleUrls: [ './app.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {

	constructor(){}

}
