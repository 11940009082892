import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TcHelpService } from '../../../../services/tc-help.service';

@Component({
	selector: 'help-articles',
	templateUrl: './help.html',
	styleUrls: ['./help.scss'],
})
export class HelpComponent implements OnInit{
	public helpArticles: any;
	public currentPage = 1;

	showWhatsNew = false;
	showFullContent = false;
	selectedArticle: any;
	showHelpCategories = false;
	helpCategories: any;
	helpMethodLabel = 'What\'s new';
	helpMethod = 3;
	// selectedHelpCategories: Array<String> = [];
	noResultsFound = false;
	showExcerpt = false;
	constructor(private tcHelpService: TcHelpService) { }

	@Input() cityApplications: any;
	@Input() showHelp: Boolean;

	// @Output() showHelp;
	@Output() close = new EventEmitter<void>();

	ngOnInit() {
		this.setCityHelpCategories(this.cityApplications);
	}

	async setCityHelpCategories	(cityApplications) {
		this.helpCategories = await this.tcHelpService.getCityHelpCategories(cityApplications);
		this.getWhatsNew();
		this.changeHelpMethod(3);
	}

	
	public async getWhatsNew() {
		await this.tcHelpService.getWhatsNew().subscribe((response) => {
			this.helpArticles = response;
			return this.helpArticles;
		});
	}

	public async searchHelp(searchValue) {
		await this.tcHelpService.getHelpArticles(searchValue).subscribe((response) => {
			this.helpArticles = response;
			if (this.helpArticles.length === 0) {
				this.noResultsFound = true;
			}
			this.showExcerpt = true;
			return this.helpArticles;
		});
	}

	public async searchHelpCategories(selectedHelpCategories) {
		await this.tcHelpService.getHelpArticlesByCategory(selectedHelpCategories).subscribe((response) => {
			this.helpArticles = response;
			if (this.helpArticles.length === 0) {
				this.noResultsFound = true;
			}
			this.showExcerpt = true;
			return this.helpArticles;
		});
	}

	searchByCategory() {
		const formData = document.querySelectorAll('.searchCategory') as unknown as HTMLInputElement[] | null;
		const formCategories = Array.from(formData);
		console.log('form categories');
		console.log(formCategories);
		let checkedCategories = 0;
		let selectedHelpCategories = [];
		formCategories.forEach(function(category) {
			category.checked === true && checkedCategories++;
			if (category.checked === true) { 
				selectedHelpCategories.push(category.value);
			}
		});
		
		if (checkedCategories) {
			this.searchHelpCategories(selectedHelpCategories);
		} else { 
			formCategories[formCategories.length - 1].setCustomValidity('You must check at least one checkbox');
			formCategories[formCategories.length - 1].reportValidity();
		}
	}


	// next() {
	// 	this.tcHelpService.nextPage(this.currentPage += 1).subscribe(data => {
	// 		if (data) {
	// 			this.helpArticles = data;
	// 		}
	// 	});
	// }

	// previous() {
	// 	if (this.currentPage === 1) return;

	// 	this.tcHelpService.previousPage(this.currentPage -= 1).subscribe(data => {
	// 		if (data) {
	// 			this.helpArticles = data;
	// 		}
	// 	});
	// }

	hideShowWhatsNew() {
		this.showWhatsNew = false;
	}

	toggleShowWhatsNew() {
		if (!this.helpArticles) {
			this.tcHelpService.getWhatsNew().subscribe((response) => {
				this.helpArticles = response;
			});
		}
		this.showWhatsNew = !this.showWhatsNew;
	}

	hideShowFullContent() {
		this.showFullContent = false;
		this.selectedArticle = undefined;
	}

	toggleShowFullContent(release) {
		this.selectedArticle = release;
		this.showFullContent = !this.showFullContent;
	}

	hideShowHelpCategories() {
		this.showHelpCategories = false;
	}

	toggleShowHelpCategories() {
		this.showHelpCategories = !this.showHelpCategories;	
	}
	
	changeHelpMethod(method) {

		this.helpMethod = method;
		this.noResultsFound = false;

		switch (method) {
			case 1:
				this.helpMethodLabel = 'Explore help topics';
				this.toggleShowHelpCategories();
				this.showExcerpt = false;
				break;
			case 2:
				this.helpMethodLabel = 'Search';
				this.showExcerpt = false;
				break;
			case 3:
				this.helpMethodLabel = 'What\'s new';
				this.showExcerpt = true;
				this.helpArticles = undefined;
				this.toggleShowWhatsNew();
				break;
		}
	}

	closeHelp() {
		this.close.emit();
	}

}