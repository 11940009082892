import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Field } from '../../../../dataTypes/dataTypes';
import { FormService } from '../../../../services/services';
import {
	BusinessesResource,
	// Address,
	Business
} from '@towncloud/thor-api';

@Component({
	selector: 'tc-business',
	templateUrl: './newBusinessModal.html',
	styleUrls: [ './newBusinessModal.scss'],
})

export class NewBusinessModalComponent {
	@Input() defaults: any = {};
	@Input() newBusiness = undefined;
	@Output() newBusinessChange: EventEmitter<any> = new EventEmitter();

	business: Business;
	fields: any = {};
	physicalAddress: any = {};
	disableMailingAddress = false;
	isValid = false;
	addressIsValid: boolean;

	constructor(
		public businessesResource: BusinessesResource,
		private formService: FormService,
	) {

		this.fields.name = new Field({
			type: 'input',
			label: 'Business Name',
			required: true
		});

		this.fields.doingBusinessAs = new Field({
			type: 'input',
			label: 'Doing Business As',
			required: true
		});
		
	}

	ngOnInit() {
		this.applyDefaults();
	}

	applyDefaults() {
		// console.log(this.defaults);
		this.fields.name.value = this.defaults.name;
		this.physicalAddress.address1 = this.defaults.address1;
		this.physicalAddress.address2 = this.defaults.address2;
		this.physicalAddress.city = this.defaults.city;
		this.physicalAddress.zipCode = this.defaults.zipCode;
		this.physicalAddress.state = this.defaults.stateId;
		this.physicalAddress.stateName = this.defaults.stateName;
		this.physicalAddress.country = this.defaults.countryId;
	}

	fieldChanged() {
		if (this.formService.isRequiredFieldsValid(this.fields)) {
			this.isFormValid();
		}
		
	}

	isFormValid() {
		this.isValid = this.addressIsValid;
	}
	
	createBusiness() {
		const params = {
			'name': this.fields.name.value,
			'doingBusinessAs': this.fields.doingBusinessAs.value,
			'physicalAddress': {
			'address1': this.physicalAddress.address1,
			'address2': this.physicalAddress.address2,
			'city': this.physicalAddress.city,
			'stateId': this.physicalAddress.state,
			'stateName': this.physicalAddress.stateName,
			'zipCode': this.physicalAddress.zipCode,
			'countryId': this.physicalAddress.country,
			},
		};

		this.businessesResource.create( params ).then( data => {
			this.business = data;
			this.newBusinessChange.emit(this.business);
		});
	}

}
