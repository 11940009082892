import { Injectable, } from '@angular/core';

import { Field } from '../../dataTypes/dataTypes';
import {
	UsersResource,
	ResourceParams
} from '@towncloud/thor-api';

@Injectable()
export class UsersGrid {

	constructor(
		private usersResource: UsersResource
	) {}

	settings() {
		return {
			columns: [
				{
					label: 'Email',
					field: 'email'
				},
				{
					label: 'First Name',
					field: 'firstName'
				},
				{
					label: 'Last Name',
					field: 'lastName'
				},
				{
					label: 'Last Login',
					field: 'lastLoginDate',
					format: 'datetime'
				},
				{
					label: 'Created At',
					field: 'dateCreated',
					format: 'datetime'
				},
				{
					label: 'Updated At',
					field: 'dateUpdated',
					format: 'datetime'
				},
				{
					label: 'Password Reset Required',
					field: 'passwordResetRequired',
					format: 'yes/no'
				}
			],
			filters: [
				{
					property: 'email',
					operator: 'contains',
					field: new Field({
						type: 'input',
						label: 'Email'
					})
				},
				{
					property: 'firstName',
					operator: 'contains',
					field: new Field({
						type: 'input',
						label: 'First Name'
					})
				},
				{
					property: 'lastName',
					operator: 'contains',
					field: new Field({
						type: 'input',
						label: 'Last Name'
					})
				},
				{
					property: 'lastLoginDate',
					operator: 'eq',
					field: new Field({
						type: 'datetime',
						label: 'Last Login'
					})
				},
				{
					property: 'dateCreated',
					operator: 'eq',
					field: new Field({
						type: 'datetime',
						label: 'Created At'
					})
				},
				{
					property: 'dateUpdated',
					operator: 'eq',
					field: new Field({
						type: 'datetime',
						label: 'Updated At'
					})
				},
				{
					property: 'passwordResetRequired',
					operator: 'eq',
					field: new Field({
						type: 'checkbox',
						label: 'Password Reset Required',
						id: 'passwordRequiredFilter'
					})
				}
			],
			resource: this.usersResource,
			resourceParams: new ResourceParams({
				sort: [{
					field: 'email',
					order: 'ASC'
				}]
			})
		};
	}
}
