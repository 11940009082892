import { DataType } from './dataType';
import { Field } from './field';

import {
	ResourceParams
} from '@towncloud/thor-api';

enum gridSortOrder { 'ASC' = <any>'ASC', 'DESC' = <any>'DESC' }
enum gridColumnType { 'label' = <any>'label', 'checkbox' = <any>'label' }
const gridItemsPerPage = [10, 25, 50];

export class GridFilter extends DataType {
	filterable: boolean;
	property: string;
	operator: any;
	value?: any;
	field: Field;

	constructor(object: any = {}) {
		var properties = ['filterable', 'property', 'operator', 'value', 'field'];
		super(object, properties);

		// this.field = new Field(object['field']);
		if (object.operator) {
			this.operator = this.getFilterOperators(object.operator);
		}
	}

	private getFilterOperators(operators) {
		if (Array.isArray(operators)) {
			const options = [];
			operators.forEach(operator => {
				options.push(this.getFilterOption(operator))
			});

			return new Field({
				type: 'select',
				options: options,
				optionLabel: 'label',
				required: true,
			});
		} else {
			const options = [this.getFilterOption(operators)];
			return new Field({
				type: 'select',
				options: options,
				optionLabel: 'label',
				value: options[0],
				disabled: true,
			});
		}
	}

	private getFilterOption(operator) {

		let option;

		switch (operator) {
			case 'eq':
				option = {
					value: operator,
					label: '='
				};
				break;
			case 'lt':
				option = {
					value: operator,
					label: '<'
				};
				break;
			case 'lte':
				option = {
					value: operator,
					label: '<='
				};
				break;
			case 'gt':
				option = {
					value: operator,
					label: '>'
				};
				break;
			case 'gte':
				option = {
					value: operator,
					label: '>='
				};
				break;
			case 'ne':
				option = {
					value: operator,
					label: '≠'
				};
				break;
			case 'contains':
				option = {
					value: operator,
					label: 'Contains'
				};
				break;
			default:
				option = {
					value: 'eq',
					label: '='
				};
		}

		return option;
	}
}

export class GridColumn extends DataType {
	label: string;
	field: string;
	visible: boolean;
	format: string;
	type: string;
	$checkbox: Field;
	disabled: any;
	calculateValue: any;
	align: string;
	maxWidth: number;
	showTitle: boolean;

	private _sortOrder?: string;
	get sortOrder(): string {
		return this._sortOrder;
	}
	set sortOrder(order: string) {
		this._sortOrder = gridSortOrder[order];
	}

	constructor(object: Object = {}, properties = undefined) {

		if (!properties) {
			properties = ['label', 'field', 'sortOrder', 'visible', 'format', 'type', 'disabled', 'calculateValue', '_sortOrder', 'align', 'maxWidth', 'showTitle'];
		}

		super(object, properties);

		if (!this.type) {
			this.type = 'label';
		}

		if (this.visible == undefined) {
			this.visible = true;
		}
	}
}

export class GridSetting extends DataType {

	_id: number;
	gridId: number;
	name: string;
	userId: number;
	columns: GridColumn[];
	filters: GridFilter[];
	city: string;

	constructor(object: Object = {}) {

		const properties = ['_id', 'gridId', 'name', 'userId', 'columns', 'filters', 'city'];
		super(object, properties);

	}

}

export class Grid extends DataType {

	id: string;
	showReports: boolean;
	showDownload: boolean;
	showTools: boolean;
	showTotals: boolean;
	reset: boolean;

	rows: Array<any> = new Array();
	count: number = 0;
	totals: any;
	offset: number = 0;
	limit: number = 10;
	resource: any;
	reports: Field = new Field({
		type: 'select',
		options: [],
		optionLabel: 'name'
	});

	settings: any;

	private _columns: Array<GridColumn> = new Array();
	get columns() {
		return this._columns;
	}
	set columns(columns) {
		columns.forEach((column) => {
			this.addColumn(column);
		});
	}

	private _filters: Array<GridFilter> = new Array();
	get filters() {
		return this._filters;
	}
	set filters(filters) {
		filters.forEach((filter) => {
			this.addFilter(filter);
		});
	}

	private _resourceParams: ResourceParams;
	get resourceParams() {
		return this._resourceParams;
	}
	set resourceParams(resourceParams) {
		this.setResourceParams(resourceParams);
	}

	private _itemsPerPage: number = 10;
	get itemsPerPage(): number {
		return this._itemsPerPage;
	}
	set itemsPerPage(items: number) {
		var val = 10;
		gridItemsPerPage.forEach((item) => {
			if (item == items) {
				val = items;
			}
		});

		this._itemsPerPage = val;
	}

	constructor(object: Object = {}) {
		super(object);
		this.showTools = true;
		this.overridePushForArrays();
	}

	overridePushForArrays() {
		var args = arguments;
		var arrays = ['columns', 'filters'];

		arrays.forEach((value: string) => {

			switch (value) {
				case 'columns':
					this.definePush(this._columns, value);
					break;
				case 'filters':
					this.definePush(this._filters, value);
					break;
				default:
			}
		});
	}

	definePush(array, value) {

		var getObject = function (value, arg) {

			var val;
			switch (value) {
				case 'columns':
					val = new GridColumn(arg);
					break;
				case 'filters':
					val = new GridFilter(arg);
					break;
				default:
			}

			return val;
		};

		array.push = function () {
			var list = [];

			for (var i = 0; i < arguments.length; i++) {
				list.push(getObject(value, arguments[i]));
			}

			return Array.prototype.push.apply(this, list);
		};
	}

	addColumn(column) {
		this._columns.push(column);
	}
	addFilter(filter) {
		this._filters.push(filter);
	}
	setResourceParams(params) {
		this._resourceParams = params;
	}
}