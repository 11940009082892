import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { CurrentUserResource } from '../resources/currentUser.resource';


import {
	AccessTokenResource,
	ApiService,
	ResourceParams,
	User,
	UsersResource
} from '@towncloud/thor-api';

@Injectable()
export class LoginService {

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accessTokenResource: AccessTokenResource,
		private usersResource: UsersResource,
		private currentUserResource: CurrentUserResource
	) { }

	async login(email, password) {
		const data = {
			email: email,
			password: password
		};

		try {
			const response:any = await this.accessTokenResource.create(data);

			localStorage.setItem('accessToken', JSON.stringify(response));
			ApiService.setToken(response.token);

			return await this.getUser(email);

		} catch (e) {
			return false;
		}
	}

	async getUser(email) {
		let user = new User();
		const params = new ResourceParams({
			filters: [
				[
					{
						property: 'email',
						operator: 'eq',
						value: email
					}
				]
			]
		});

		await this.usersResource.get(params).then(data => {
			user = data.items[0];
		});

		this.currentUserResource.set(user);

		return user;

	}
}