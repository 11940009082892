import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { CurrentTownService } from '../services/currentTown.service';

@Injectable()
export class DownloadResource {
	public ApiUrl = '/';
	public ServerWithApiUrl = environment.candygramHostName + '/';

	private actionUrl: string;

	url = 'csv';

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService
	) {}

	get(params, type) {
		this.url = type;

		return new Promise(resolve => {
			this.getHeader().then(options => {
				window.location.href =
					this.ServerWithApiUrl + this.url + '?file=' + params.file;

				resolve();
			});
		});
	}

	post(data, type) {
		this.url = type;

		return new Promise(resolve => {
			this.getHeader().then(options => {
				this.http
					.post(this.ServerWithApiUrl + this.url, data, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then(data => {
						resolve(data);
					});

				// this.http
				// 	.post(this.ServerWithApiUrl+this.url, data, options)
				// 	.subscribe( res => {

				// 		resolve(this.extractData(res));
				// 	})
			});
		});
	}

	private extractData(res: any) {
		let headers: any = {};
		res.headers.keys().forEach(h => {
			headers[h] = res.headers.get(h);
		});

		return { body: res.body, headers: headers };
	}

	private handleError(error: any) {
		console.error(error);
		return error;
	}

	private getHeader() {
		const promise = new Promise((resolve, reject) => {
			let headers = new HttpHeaders();

			const user = JSON.parse(localStorage.getItem('user'));

			const accessToken = JSON.parse(localStorage.getItem('accessToken'));

			const currentEntity = JSON.parse( sessionStorage.getItem('entity'));

			this.currentTownService.get().then(town => {
				if (accessToken) {
					headers = headers.set('X-Token', accessToken.token);
				}

				if (town) {
					headers = headers.set('X-City', town.slug);
				}

				if (currentEntity) {
					headers = headers.set('X-Entity', currentEntity.id.toString());
				}

				const options = {
					headers: headers,
					observe: 'response'
				};

				resolve(options);
			});
		});

		return promise;
	}
}
