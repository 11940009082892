import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
// import { Observable } from 'rxjs';

import { CurrentTownService } from './currentTown.service';
// import { OpportunityTypeDocumentTypesResource } from '@towncloud/thor-api';
import { environment } from '../environments/environment';


const injector = Injector.create({ providers: [
	{ provide: HttpClient, deps: [] },
	{ provide: CurrentTownService, deps: [] }
]});

@Injectable()
export class HammerApiService {

	private  requestQueue: Array<any> = [];

	public ServerWithApiUrl = environment.candygramHostName + '/';

	public processingRequest: boolean = false;

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService
	) {
	}

	moveToNextRequest() {
		this.requestQueue.shift();

		this.loopRequests();
	}

	loopRequests() {

		if (this.requestQueue.length > 0) {

			this.processingRequest = true;

			switch (this.requestQueue[0].type) {
				case 'get':
						this.executeGet(this.requestQueue[0]);
					break;
				case 'put':
						this.executePut(this.requestQueue[0]);
					break;
				case 'post':
						this.executePost(this.requestQueue[0]);
					break;
				case 'delete':
						this.executeDelete(this.requestQueue[0]);
					break;
				case 'getHtml':
					this.executeGetHtml(this.requestQueue[0]);
				break;
				default:
					this.executeGet(this.requestQueue[0]);
			}
		} else {
			this.processingRequest = false;
		}
	}

	get(url, data): Promise<any> {

		let queryParams = this.buildQueryString(data);

		let promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'get',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url + queryParams
			});
		});

		if (this.processingRequest === false) {

			this.loopRequests();
		}

		return promise;

	}

	executeGet(request) {

		this.getHeader().then( options => {

				this.http
					.get(this.ServerWithApiUrl + request.url, options)
					.toPromise()
					.then(this.extractData)
							.catch( (e) => {

								request.reject(e);

								this.moveToNextRequest();
							})
							.then( data => {

								request.resolve(data);

								this.moveToNextRequest();
							});
			});
	}

	post(url, data): Promise<any> {

		let promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'post',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			});
		});

		if (this.processingRequest === false) {
			this.loopRequests();
		}

		return promise;

	}

	executePost(request) {

		this.getHeader().then( options => {
			this.http
				.post(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {

					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
						.catch( (e) => {

							request.reject(e);

							this.moveToNextRequest();
						});
		});
	}

	put(url, data): Promise<any> {

		let promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'put',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			});
		});

		if (this.processingRequest === false) {
			this.loopRequests();
		}

		return promise;

	}

	executePut(request) {

		this.getHeader().then( options => {
			this.http
				.put(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
						.catch( (e) => {

							request.reject(e);

							this.moveToNextRequest();
						});
		});
	}

	delete(url): Promise<any> {

		let promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'delete',
				resolve: resolve,
				reject: reject,
				url: url
			});
		});

		if (this.processingRequest === false) {
			this.loopRequests();
		}

		return promise;

	}

	executeDelete(request) {

		this.getHeader().then( options => {

				this.http
				.delete(this.ServerWithApiUrl + request.url, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
						.catch( (e) => {

							request.reject(e);

							this.moveToNextRequest();
						});
			});
	}

	getHtml(url): Promise<any> {
	// this get differs for the original get(url, data) in that html and not data is expected in return
		let promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'getHtml',
				resolve: resolve,
				reject: reject,
				url: url
			});
		});

		if (this.processingRequest === false) {

			this.loopRequests();
		}

		return promise;

	}

	executeGetHtml(request) {
		this.getHeader('text').then( options  => {
				this.http
					.get(this.ServerWithApiUrl + request.url, options )
					.toPromise()
					.then(this.extractData)
							.catch( (e) => {

								request.reject(e);

								this.moveToNextRequest();
							})
							.then( data => {

								request.resolve(data);

								this.moveToNextRequest();
							});
			});
	}

	create(url, data): Promise<any> {

		return this.post(url, data);
	}

	update(url, data): Promise<any> {

		return this.put(url, data);
	}

	private extractData(res: any) {

		let body = res;

		return body;
	}

	private handleError(error: any) {

		return error;
		// return Observable.throw(error)
	}

	getHeader(responseType = 'none') {
		const promise = new Promise( (resolve, reject) => {
			let headers = new HttpHeaders();

			const user = JSON.parse( localStorage.getItem('user') );

			const accessToken = JSON.parse( localStorage.getItem('accessToken') );

			const currentEntity = JSON.parse( sessionStorage.getItem('entity'));

			this.currentTownService.get().then( town => {

				if (accessToken) {
					headers = headers.set('X-Token', accessToken.token);
				}

				if (town) {
						headers = headers.set('X-City', town.slug);
					}

				if (currentEntity) {
					headers = headers.set('X-Entity', currentEntity.id.toString());
				}

				const options: { [key: string]: any } = {};
				options.headers = headers;
				// responseType is an optional parameter. only used for the new getHtml function
				if (responseType !== 'none') {
					options.responseType = responseType;
				}
				resolve(options);
			});

		});

			return promise;
	}

	buildQueryString(params) {
				let queryParams = '?offset=' + params.offset + '&limit=' + params.limit;
				if (params.sort) {
						if (params.sort.length > 0) {
								let sortString = '';
								params.sort.forEach(value => {
										if (sortString.length > 0) {
												sortString += ',';
										}
										sortString += value.field + ':' + value.order;
								});
								queryParams += '&sortby=' + sortString;
						}
				}
				if (params.filters) {
						let filterString = '';
						params.filters.forEach(filter => {
								let fieldString = '';
								filter.forEach(field => {
										if (fieldString.length > 0) {
												fieldString += ',';
										}
										fieldString += field.property + ':' + field.operator + ':' + field.value;
								});
								if (fieldString.length > 0) {
										fieldString = '(' + fieldString + ')';
								}
								if (filterString.length > 0) {
										filterString += ',';
								}
								filterString += fieldString;
						});
						queryParams += '&filter=' + encodeURIComponent(filterString);
				}
				if (params.expand) {
						queryParams += '&expand=' + params.expand;
				}
				return queryParams;
		}
}