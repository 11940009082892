import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import { CurrentUserResource } from './../../../../resources/currentUser.resource';
import { environment } from '../../../../environments/environment';
import {
	ApiService,
	ResourceParams,
	JobOutputsResource,
	JobOutput, 
	User
} from '@towncloud/thor-api';


@Component({
	selector: 'my-outputs',
	templateUrl: './myOutputs.component.html',
	styleUrls: [ './myOutputs.component.scss' ],
})

export class MyOutputsComponent implements OnInit{
	@ViewChild('downloadLink') private downloadLink: ElementRef;
	output: JobOutput;
	outputs = [];
	currentUser: User;

	constructor(
		private http: HttpClient,
		private currentUserResource: CurrentUserResource,
		private jobOutputsResource: JobOutputsResource,
		private apiService: ApiService
	) {}

	async ngOnInit() {
		await this.currentUserResource.get().then(user => {
			this.currentUser = user;
		});
		// console.log(this.currentUser);
		await this.getOutputs();
		// console.log(this.outputs);
	}

	async getOutputs() {
		const params = new ResourceParams({
			offset: 0,
			limit: 5,
			sort: [
				{
					field: 'id',
					order: 'DESC',
				},
			],
			filters: [
				[
					{
						property: 'jobCreatedBy',
						operator: 'eq',
						value: this.currentUser.id,
					},
					{
						property: 'deleted',
						operator: 'eq',
						value: false,
					},
				],
			],
		});

		await this.jobOutputsResource.get(params).then(data => {
			this.outputs = data.items;
		});

	}


	async downloadOutput(row) {
		// console.log('downloadOutput');
		// console.log(row);
		const params = new ResourceParams({
			$all: true,
			urlModifiers: {
				id: row.id,
				file: true,
			},
		});

		this.jobOutputsResource.init(params);
		const ServerWithApiUrl = environment.thorHostName + '/';
		const url = ServerWithApiUrl + this.jobOutputsResource.url;
		const options: any = await this.apiService.getHeader();
		options.responseType = 'arraybuffer';

		const file = await this.http.get(url, options).toPromise();
		// console.log(options);
		this.downloadFile(file, row.name, 'application/text');
	}

	downloadFile(data: any, name: string, type: string) {
		const blob = new Blob([data], { type: type });
		const url = window.URL.createObjectURL(blob);

		const link = this.downloadLink.nativeElement;
		link.href = url;
		link.download = name;
		link.click();

		window.URL.revokeObjectURL(url);
	}
}