import { Injectable } from '@angular/core';
import { CurrentUserService } from '../services/currentUser.service';
import { CurrentTownService } from '../services/currentTown.service';
import {
	ApiService,
	RolePermissionsResource,
	ResourceParams,
	User,
} from '@towncloud/thor-api';

export let CURRENT_USER: User = new User();

@Injectable({
  providedIn: 'root',
})
export class CurrentUserResource {
	private instanceId: number;
	permissions: any;
	timeZone = '';

	constructor(
		private currentUserService: CurrentUserService,
		private currentTownService: CurrentTownService,
		private rolePermissionsResource: RolePermissionsResource,
	) {
		this.instanceId = Date.now(); // Assign a unique ID based on the current timestamp
		// console.log('CurrentUserResource constructor');
		// console.log('Instance ID:', this.getInstanceId());
		let validToken = false;
		if (localStorage.getItem('accessToken')) {
			validToken = this.validToken();
			if (validToken) {
				const accessToken = JSON.parse(localStorage.getItem('accessToken'));
				ApiService.setToken(accessToken.token);
			}
		}
	
		if (localStorage.getItem('user')) {
			CURRENT_USER = JSON.parse(localStorage.getItem('user'));
		}
		
		if ((localStorage.getItem('accessToken')) && (localStorage.getItem('user')) && validToken) {
			// console.log('@ @ @ @ @ current user resource -constructor - get user roles');
			this.expandUserRoles();
		}
	
	}

	async init() {
		if ((localStorage.getItem('accessToken')) && (localStorage.getItem('user'))) {
			// console.log('@ @ @ @ @ current user resource - init');
			await this.expandUserRoles();
		}
	}

	getInstanceId(): number {
    return this.instanceId;
  }

	get(): Promise<any> {
		// console.log('@ @ @ @ @ @ currentUser resource - get  @ @ @ @ @ @');
		if (localStorage.getItem('user')) {
			CURRENT_USER = JSON.parse(localStorage.getItem('user'));
		}

		// do something to get current user from API
		if (CURRENT_USER.id) {
			return new Promise((resolve, reject) => {
				resolve(CURRENT_USER);
			});
		} else {
			return new Promise((resolve, reject) => {
				resolve({});
			});
		}
	}

	set(user: User) {
		// console.log('@ @ @ @ @ @ current user resource - set user @ @ @ @ @ @', user);
		// console.log(CURRENT_USER);
		if (user && CURRENT_USER.id == user.id) {
			// if user is defined and CURRENT_USER is equal user udate local storage (password reset case)
			localStorage.setItem('user', JSON.stringify(user));
		}

		if (user && CURRENT_USER.id != user.id) {
			// if user is not undefined and CURRENT_USER not equal user udate local storage, permissions , and emit change
			localStorage.setItem('user', JSON.stringify(user));
			if (this.permissions === undefined || CURRENT_USER !== user) {
				this.expandUserRoles();
			}
			this.currentUserService.emitChangeEvent(CURRENT_USER);
		}
		
		if (CURRENT_USER.id && user == undefined) {
			// if user is undefined and CURRENT_USER is a valid user (has id), app is logging out emit change
			// if user is undefined and CURRENT_USER is not a valid user DO NOT emit change
			localStorage.removeItem('user');
			localStorage.removeItem('accessToken');
			CURRENT_USER = new User();
			this.currentUserService.emitChangeEvent(CURRENT_USER);
		}

	}

	async expandUserRoles() {
		const userRoles = this.getUserRoles();
		// console.log('@ @ @ @ @ userRoles - ', userRoles);
		const userRolesArray = userRoles.split(',');
		// console.log('@ @ @ @ @ userRolesArray - ', userRolesArray);

		const data = new ResourceParams({
			limit: 9999,
			filters: [
				[
					{
						property: 'roleId',
						operator: 'in',
						value: '[' + userRolesArray.join(',') + ']'
					}
				]
			]
		});
		// console.log('@ @ @ @ @ getrolepermissions @ @ @ @ @');
		// console.log(data);
		await this.rolePermissionsResource.get(data).then(permissions => {
			// console.log(permissions);
			this.permissions = permissions;
			// console.log('@ @ @ @ @ @ @ this.permissions - ',this.permissions);
		})
	}

	hasPermission(permission) {
		// console.log('@ @ @ @ @ current user resource - hasPermission - ', permission);
		// console.log(this.permissions.items.length);
		if (this.permissions.items.some(p => p.permission == permission)) {
			return true;
		} else {
			return false;
		}
	}

	validToken() {
		let pass = false;
		const accessToken = JSON.parse(localStorage.getItem('accessToken'));
		this.getTimeZone();
		

		if (accessToken) {
			const tokenExpires = new Date(this.getTokenExpiration(accessToken));
			const dateToday = new Date();
			const dateInTimezone = dateToday.toLocaleString('en-US', { timeZone: this.timeZone });
			const expInTimezone = tokenExpires.toLocaleString('en-US', { timeZone: this.timeZone });

			const dateCityTZ = new Date(dateInTimezone);
			const expCityTZ = new Date(expInTimezone);

			if (dateCityTZ >= expCityTZ) {
				pass = false;
			} else {
				pass = true;
				return pass;
			}
		}
	}

	getUserRoles() {
		const accessToken = JSON.parse(localStorage.getItem('accessToken'));
		const token = accessToken['token'];
		
		try {
			const decoded = JSON.parse(atob(token.split('.')[1]));
			
			return decoded['user_roles'];
		} catch (err) {
			console.log('========== invalid token ==============');
			console.log(err);
			return false;
		}		
	}

	getTokenExpiration(accessToken) {
		// GKB 01/14/2024 revised token expiration strategy. Expiration now is client time zone based, expiring at 23:59:00 of current day in city's time zone
		const token = accessToken['token'];
		try {
			const decoded = JSON.parse(atob(token.split('.')[1]));
			// expiration from thor (ruby) is in seconds. need to convert to milliseconds
			const expInMillisec = decoded['exp'] * 1000;
			return expInMillisec;
		} catch (err) {
			console.log('========== invalid token ==============');
			console.log(err);
			return 0;
		}		
	}

	getTimeZone() {
		if (this.timeZone == '') {
			this.timeZone = this.currentTownService.getTimeZone();
		}
	}
}
