import { Component, ViewEncapsulation, Input } from '@angular/core';

import { WizardComponent } from './wizard.component';

@Component({
  selector: 'step',
  templateUrl: './step.html',
  encapsulation: ViewEncapsulation.None
})

export class WizardStepComponent { 

	@Input() title: string;
	@Input() valid: boolean;
	active: boolean;

	constructor(wizard:WizardComponent) {

		if( this.valid == undefined){
			this.valid = true;
		}
		
		wizard.addStep(this);
	}
}