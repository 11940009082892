import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class FormService {

	constructor() {}

	isRequiredFieldsValid(fields) {

		for (const prop in fields) {

			if (fields[prop].required) {

				if ( fields[prop].type === 'multiple' ) {

					for (let i = 0; i < fields[prop].fields.length; i++) {
						if (!this.valueIsSet(fields[prop].fields[i].value)) {
							return false;
						}
					}
				} else if (fields[prop].type === 'file' ) {
					if (!this.valueIsSet(fields[prop].value) || fields[prop].value.length === 0) {
						return false;
					}
					// added following validation for number field if both min and max are set and min !== max
					// when value exists it must not be less than the min or more than the max otherwise feild is not valid
				} else if (fields[prop].type === 'number'
									&& fields[prop].min && fields[prop].max
									&& this.valueIsSet(fields[prop].value)
									&& (fields[prop].min !== fields[prop].max)) {
					if ((fields[prop].value < fields[prop].min) || (fields[prop].value > fields[prop].max)) {
						return false;
					}
				} else if ( !this.valueIsSet(fields[prop].value) ) {
					return false;
				}

			}
		}

		return true;
	}

	private valueIsSet(value) {
		if (value == undefined) {
			return false;
		} else if (typeof value == 'string') {
			
			if (value.trim().length == 0) {
				return false;
			}
		}

		return true;
	}
}