import { Component, ViewEncapsulation, Input } from '@angular/core';

import { TabsComponent } from './tabs.component';

@Component({
  selector: 'tab',
  templateUrl: './tab.html',
  encapsulation: ViewEncapsulation.None
})

export class TabComponent { 

	@Input() title: string;
	active: boolean;

	constructor(tabs:TabsComponent) {
		tabs.addTab(this);
	}
}