import { Pipe } from '@angular/core';

import * as moment from 'moment';

@Pipe({
    name: 'gridColumnFormatPipe'
})
export class GridColumnFormatPipe {

    transform(item, args): any {

        if (args.format) {
            switch (args.format) {
                case 'month':
                    if (item) {
                        return moment(item, 'M').format('MMMM');
                    }
                    return item;
                case 'date':
                    if (item) {
                        return moment(item).format('MM/DD/YYYY');
                    }
                    return item;
                case 'datetime':
                    if (item) {
                        return moment(item).format('MM/DD/YYYY h:mm a');
                    }
                    return item;
                case 'currency':
                    if (item == undefined) {
                        item = 0.00
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          });
                          return formatter.format(item);
                    } else {
                    if (item) {
                        // return '$' + parseFloat(item).toFixed(2);
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          });
                          return formatter.format(item);
    
                        }
                    }
                    return item;
                case 'phoneNumber':
                    // This mask is intended for 10 digit numeric phone numbers 
                    if (item) {
                        if (item.toString().length == 10){
                            const phoneNumberString = item.toString();
                            const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                            if (match) {
                                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
                            }
                        }

                    }
                    return item;    
                case 'percentage':
                    if (item) {
                        return item + ' %';
                    }
                    return item;
                case 'yes/no':

                    if (item == true) {
                        return 'Yes';
                    } else if (item == false) {
                        return 'No';
                    } else if (item === undefined) {
                        return 'No';
                    }

                    return item;

                default:
                    return item;
            }
        }

        return item;
    }
}