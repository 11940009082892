import { Pipe, PipeTransform } from "@angular/core";
import { CreditCard } from "../classes/creditCard";
import { MonetaryAmount } from "../classes/monetaryAmount";
import { PhoneNumber } from "../classes/phoneNumber";
@Pipe({
	name: "fieldMask",
})
export class FieldMask implements PipeTransform {
	transform(value, mask) {
		let maskedValue = value;

		switch (mask) {
			case "creditCard":
				const cc = new CreditCard(value);
				maskedValue = cc.format();
				break;
			case "money":
				const ma = new MonetaryAmount(value, '$');
				maskedValue = ma.format();
				break;
			case "phoneNumber":
				const ph = new PhoneNumber(value);
				maskedValue = ph.format();	
		}

		return maskedValue;
	}
}
