import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ChartOptions } from 'chart.js';



@Component({
  selector: 'budgetChart',
  templateUrl: './budgetChart.component.html',
  styleUrls: ['./budgetChart.component.css']
})
export class BudgetChartComponent {
  @ViewChild('pieChart', {static: false}) myCanvas: ElementRef;
  @Input() pieChartData: [];
  // @Input() pieChartLegend: boolean;
  @Input() pieChartLabels: [];
  @Input() pieChartTitle: string;
  // @Input() pieChartOptions: any={};

  public pieChartPlugins = [pluginDataLabels];
 
  public pieChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'end',
        display: 'auto',
        color: 'black',
        borderColor: 'black',
        borderRadius: 5,
        borderWidth: 2,
        font: {
          size: 14,
          weight: 'bold',
        },
        formatter: function(value, context) {
          // console.log(context);
          // console.log(value);
          const datapoints: any = context.chart.data.datasets[0].data;
          function totalSum(total, datapoint) {
            return total + datapoint;
          }
          const totalValue: number = datapoints.reduce(totalSum, 0);
          const percentValue = (value / totalValue * 100).toFixed(1);
          const money = '$' + (value * 1).toLocaleString();
          const display = [`${money}`, `${percentValue}%`]
          return display;
        }
      }
    }
  };

  
  public pieChartType = "'pie'";
  public pieChartLegend = true;
  public context: any;

  constructor() {
  }

  // ngAfterViewInit(): void {
  //   this.context = this.myCanvas.nativeElement.getContext('2d');
  // }
}
