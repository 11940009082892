import { Component } from '@angular/core';

@Component({
	templateUrl: './notAuthorized.html',
	styleUrls: ['./notAuthorized.scss'],
})

export class NotAuthorizedComponent {

	constructor() {}

}
