import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataService } from '@towncloud/thor-api';

import { CurrentTownService } from '../services/currentTown.service';
import { environment } from '../environments/environment';

@Injectable()
export class CitySettingsResource {
	public ApiUrl: string = '/';
	public ServerWithApiUrl = environment.candygramHostName + '/';

	private actionUrl: string;

	url = 'citysettings';

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService,
		private dataService: DataService
	) {}

	get(params) {
		return new Promise(resolve => {
			this.getHeader().then(options => {
				var queryParams = this.dataService.buildQueryString(params);

				this.http
					.get(this.ServerWithApiUrl + this.url + queryParams, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then(data => {
						resolve(data);
					});
			});
		});
	}

	post(data) {
		return new Promise(resolve => {
			this.getHeader().then(options => {
				this.http
					.post(this.ServerWithApiUrl + this.url, data, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then(data => {
						resolve(data);
					});
			});
		});
	}

	put(data) {
		return new Promise(resolve => {
			let id = data.urlModifiers.id;
			delete data.urlModifiers;

			this.getHeader().then(options => {
				this.http
					.put(this.ServerWithApiUrl + this.url + '/' + id, data, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then(data => {
						resolve(data);
					});
			});
		});
	}

	delete(data) {
		return new Promise(resolve => {
			this.getHeader().then(options => {
				this.http
					.delete(this.ServerWithApiUrl + this.url + '/' + data._id, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then(data => {
						resolve(data);
					});
			});
		});
	}

	private extractData(res: any) {
		let body = res;

		return body;
	}

	private handleError(error: any) {
		return error;
		// return Observable.throw(error)
	}

	private getHeader() {
		var promise = new Promise((resolve, reject) => {
			var headers = new HttpHeaders();

			var user = JSON.parse(localStorage.getItem('user'));

			var accessToken = JSON.parse(localStorage.getItem('accessToken'));

			this.currentTownService.get().then(town => {
				if (accessToken) {
					headers = headers.set('X-Token', accessToken.token);
				}

				if (town) {
					headers = headers.set('X-City', town.slug);
				}

				var options = {
					headers: headers
				};

				resolve(options);
			});
		});

		return promise;
	}
}
