import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { User } from "@towncloud/thor-api";
import { CurrentTownService } from "../../../../services/currentTown.service";
@Component({
	selector: "breadcrumbs",
	templateUrl: "./breadcrumbs.html",
	encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbsComponent {
	routeData: any;
	menu: Array<any>;

	config: any;
	name: string;
	title: string;
	subTitle: string;
	breadCrumbs: Array<any>;
	user: User;
	params: Object;

	private sub: any;

	constructor(private route: ActivatedRoute, private router: Router, private currentTownService: CurrentTownService) {}

	ngOnInit() {
		this.buildBodyHeader();
	}

	ngOnChanges() {}

	buildBodyHeader() {
		this.config = this.route.snapshot.data;

		if (!this.config) {
			this.config = {
				breadCrumbs: [],
			};
		}

		this.title = this.config.title;
		this.subTitle = this.config.subTitle;
		this.breadCrumbs = this.config.breadCrumbs;

		if (this.breadCrumbs.length > 0) {
			this.buildTitle();
		}
	}

	buildTitle() {
		var url = window.location.pathname.slice(
			1,
			window.location.pathname.length
		);
		var urlArray = url.split("/");

		var path = this.breadCrumbs[this.breadCrumbs.length - 1].path.split("/");
		var url = "";

		path.forEach((pathBlock, index) => {
			if (pathBlock[0] == ":") {
				if (this.title) {
					this.title = this.title.replace(pathBlock, urlArray[index]);
				}

				if (this.subTitle) {
					this.subTitle = this.subTitle.replace(pathBlock, urlArray[index]);
				}
			}
		});
	}

	navigate(crumb) {
		const params = this.route.snapshot.params;
		let path;

		if (crumb.relativeTo) {
			const route = this.router.url;
			path = route.substring(0, route.indexOf(crumb.relativeTo)) + crumb.path;
		} else {
			path = crumb.path;
		}
		// console.log('<><><><><><><><><><><><><><><>');
		// console.log(path);
		for (var key in params) {
			path = path.replace(":" + key, params[key]);
		}

		if (params["town"]) {
			if (path.indexOf(params["town"]) === -1) {
				path = params["town"] + "/" + path;
			} else {
				console.log('**** no town param ****');
			}
		} else {
			if (path.includes(':town')) {
				const townSlug = this.currentTownService.getTown();
				const newPath = path.replace(':town', townSlug);
				path = newPath;
			}
		}

		if (path.includes(':entityId')) {
			if (params['entityId']) {
				if (path.indexOf(params["entityId"]) === -1) {
					path = params["entityId"] + "/" + path;
				}
			} else {
				// console.log('<><><><><><><><><><><><><><><>');
				const urlSegments = this.router.routerState.snapshot.url.split('/');
				// console.log(urlSegments);
				if (urlSegments.length > 3) {
					const entityId = urlSegments[3];
					const newPath = path.replace(':entityId', entityId);
					path = newPath;
				} else {
					console.log('**** no entityId segment ****');
				}	
			}
		}

		this.router.navigate([path]);
	}
}
