import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class Filter implements PipeTransform {
  transform(items: any[], test) {

    return items.filter(item => {

      let val = eval('item.' + test.property);

      if (test.value.constructor.name == 'Boolean') {
        return (!!val == test.value);
      } else {
        return (val == test.value);
      }

    });

  }
}