import { Component, ViewEncapsulation, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { SortableComponent } from './sortable.component';

@Component({
  selector: '[sortableContainer]',
  template: '<ng-content></ng-content>'
  // templateUrl: './wizard.html',
  // styleUrls: ['./wizard.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class SortableContainerComponent { 

	private _sortableArray: Array<any>;

	@Input()
	set sortableContainer(array: Array<any>){
		this._sortableArray = array;
	}
	get sortableContainer(): Array<any> {
		return this._sortableArray;
	}

	dragElement: any;
	el: ElementRef;
	startIndex: number;
	endIndex: number;

	constructor( el: ElementRef){
		this.el = el;
	}

	getIndex(target, e){

		var children = e.target.parentNode.children;
		var val;
		for (var i = 0; i < children.length; i++) {
			if (children[i] == target) {
				val =  i;
			}
		}

		return val

	}

	dragStart(target, e){

		this.dragElement = target;

		this.startIndex = this.getIndex(target, e);
	}

	dragEnter(target, e){

		var position = function(){

			if( (target.offsetHeight / 2) > e.offsetY){
				return 'bottom'
			}

			return 'top';
		}()

		var container = this.el.nativeElement;

		if(position == 'top'){

			container.insertBefore(this.dragElement, target);

		}else{

			container.insertBefore(this.dragElement, target.nextSibling);
			
		}
	}

	dragEnd(target, e){

		this.endIndex = this.getIndex(target, e);

		this.moveColumn();
	}

	moveColumn(){

		var removedElement = this._sortableArray.splice(this.startIndex, 1)[0];

		this._sortableArray.splice(this.endIndex, 0, removedElement);

	}
}