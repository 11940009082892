import { Component, forwardRef, ViewEncapsulation, Input, Output, EventEmitter, NgZone, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Field } from '../../../../../dataTypes/dataTypes';

import { ComponentBase } from '../../componentBase';

import * as moment from 'moment';

@Component({
	selector: 'calendar',
	templateUrl: './calendar.html',
	styleUrls: [ './calendar.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
				provide: NG_VALUE_ACCESSOR,
				useExisting: forwardRef(() => FieldCalendarComponent),
				multi: true
		}
	]
})

export class FieldCalendarComponent extends ComponentBase {

	years: Array<Object> = new Array();
	weeks: Array<Object> = new Array();
	days: Array<Object> = new Array();
	months: Array<Object> = new Array();
	fields: any = {};
	refDate: any;
	curMonth: any;
	curYear: any;

	internalChange: boolean = false;

	@Output()select: EventEmitter<any> = new EventEmitter();

	@Input()
	outOfBounds = false;
	@Output()outOfBoundsChange: EventEmitter<any> = new EventEmitter();

	rendered = false;

	constructor(
		private zone: NgZone,
		private _elementRef: ElementRef,
	) {
		super();

		this.createYearsList();
		this.createMonthsList();

	}

	async ngOnInit() {

		await setTimeout(() => {

			if (moment(this.value).isValid()) {
				this.refDate = moment(this.value);
				this.setDateDropdowns();
			} else {
				this.refDate = moment();
				this.setDateDropdowns();
			}

			this.buildCurrentMonth();
			
		});

		this.outOfBounds = undefined;
		this.outOfBoundsChange.emit(this.outOfBounds);
		
		await setTimeout( () => {
			this.rendered = true;
			this.outOfBounds = this.elementOutOfBounds();
			this.outOfBoundsChange.emit(this.outOfBounds);
		});

	}

	private firstDay(moment) {
		return moment.clone().date(1).day(0);
	}

	private createYearsList = function() {
		this.fields.years = new Field({
			type: 'select',
			options: [],
			optionLabel: 'label'
		});

		this.years = [];
				var limit = 5;
				for(var i = limit; i > 0; i--) {
					this.fields.years.options.push({label: moment().subtract(i, 'years').year(), value: moment().subtract(i, 'years').year()});
				}

				this.fields.years.options.push({label:moment().year(), value: moment().year()});

				for(var i = 1; i <= limit; i++) {
					this.fields.years.options.push({label: moment().add(i, 'years').year(), value: moment().add(i, 'years').year()});
				}
	}

	private getYearFromList = function(index) {
		var year;

		this.fields.years.options.forEach(function(y) {
			if(y.value == index) {
				year = y;
				return false
			}
		})
		return year;
	}

	private createMonthsList = function() {

		this.fields.months = new Field({
			type: 'select',
			options: [],
			optionLabel: 'label'
		});

		this.fields.months.options.push( {label: 'Jan', value: '01'});
				this.fields.months.options.push( {label: 'Feb', value: '02'});
				this.fields.months.options.push( {label: 'Mar', value: '03'});
				this.fields.months.options.push( {label: 'Apr', value: '04'});
				this.fields.months.options.push( {label: 'May', value: '05'});
				this.fields.months.options.push( {label: 'Jun', value: '06'});
				this.fields.months.options.push( {label: 'Jul', value: '07'});
				this.fields.months.options.push( {label: 'Aug', value: '08'});
				this.fields.months.options.push( {label: 'Sep', value: '09'});
				this.fields.months.options.push( {label: 'Oct', value: '10'});
				this.fields.months.options.push( {label: 'Nov', value: '11'});
				this.fields.months.options.push( {label: 'Dec', value: '12'});
	}

	private getMonthFromList = function(index) {
		var month;
		this.fields.months.options.forEach(function(m) {
			if(m.value == index) {
				month = m;
				return false
			}
		})
		return month;
	}

	private setDateDropdowns = function() {
		this.fields.months.value = this.getMonthFromList(this.refDate.month() + 1);
		this.fields.years.value = this.getYearFromList(this.refDate.year());
	}

	private buildCurrentMonth = function() {

		var done = false, 
					date = this.firstDay(this.refDate), 
					monthIndex = date.month(), 
					count = 0;
			
			this.weeks = [];
		
			while (!done) {
					this.weeks.push({ days: this.createWeek(date.clone(), this.refDate) });
					date.add(1, "w");
					done = count++ > 2 && monthIndex !== date.month();
					monthIndex = date.month();
			}
	}

	private createWeek(date, month) {
			var days = [],
					DAYS_PER_WEEK = 7;
			
			for (var i = 0; i < DAYS_PER_WEEK; i++) {
					days.push({
							number: date.date(),
							isCurrentMonth: date.month() === month.month(),
							isToday: date.isSame(new Date(), "day"),
							date: date
					});

					date = date.clone();
					date.add(1, "d");
			}

			return days;
	}

	chooseDate = function(day) {
		this.writeValue(day.date.format('YYYY-MM-DD'));

		this.select.emit(this.value);
	}

	isSelected = function(day) {
		if(this.value != undefined) {
			if(day.date.format('YYYY-MM-DD') == this.value) {
				return true;
			}
		}

		return false;
	}

	changeRefDate = function(event) {
		this.refDate = moment(this.fields.years.value.value +'-'+this.fields.months.value.value+'-01');
		this.zone.run(()=> {this.buildCurrentMonth()});
	}

	next = function() {

		this.refDate = this.refDate.add(1, 'months');
		this.setDateDropdowns();

		this.buildCurrentMonth();
	}

	prev = function() {

		this.refDate = this.refDate.subtract(1, 'months');
		this.setDateDropdowns();

		this.buildCurrentMonth();
	}

	elementOutOfBounds() {

		const DOMRect = this._elementRef.nativeElement.getBoundingClientRect();

		if (DOMRect.bottom > (window.innerHeight || document.documentElement.clientHeight) ) {
			return true;
		}

		return false;
	}
}