import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
	PasswordResource,
	CurrentUserResource
} from '../../../../resources/resources';

import { Field } from '../../../../dataTypes/dataTypes';

import { ResourceParams, User } from '@towncloud/thor-api';

import {
	FormService,
	CurrentTownService,
	LoginService
} from '../../../../services/services';

@Component({
	selector: 'password-reset',
	templateUrl: './passwordReset.html'
})
export class PasswordResetComponent {

	token: string;

	fields: any = {
		password: new Field(),
		password2: new Field()
	}

	resetSuccessFull: boolean = false;

	errorMessage: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private passwordResource: PasswordResource,
		private formService: FormService,
		private currentUser: CurrentUserResource,
		private currentTownService: CurrentTownService,
		private loginService: LoginService
	) {

		this.fields.password = new Field({
			type: 'password',
			label: 'Password',
			id: 'password',
			required: true
		});

		this.fields.password2 = new Field({
			type: 'password',
			label: 'Confirm Password',
			id: 'password2',
			required: true
		});
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if (params['token']) {
				this.token = params['token'];
			}
		});
	}

	formIsValid(){
		if(!this.formService.isRequiredFieldsValid(this.fields)){
			return false;
		}

		if(this.fields.password.value != this.fields.password2.value){
			return false;
		}

		return true;
	}

	async resetPassword() {
		const data = {
			urlModifiers:{
				id: this.token
			},
			password: this.fields.password.value
		}

		this.passwordResource.put(data).then( async (r) => {
			console.log(r);
			console.log('this worked... probably');

			if (r.id) {

				let user = await this.loginService.login(r.email, this.fields.password.value);

				if (!(await this.validateRoute())) {
					const town = this.currentTownService.getTown();
					if (town) {
						this.router.navigate(['/', town]);
					} else {
						this.router.navigate(['/']);
					}
				}

			}

		}, (e) => {
			console.log(e);
			console.log('there was an error.');
			if(e.status == 403){
				this.errorMessage = 'The token is invalid or has expired.';
			}

		});
	}

	async validateRoute() {
		// let permissions: any;
		// permissions = await this.currentUser.getPermissions();

		let permission = '';
		if (this.router.routerState.root.firstChild.firstChild) {
			permission = this.router.routerState.root.firstChild.firstChild.routeConfig.data['permission'];
		} else {
			permission = this.router.routerState.root.firstChild.routeConfig.data['permission'];
		}

		let pass = false;

		// permissions.items.forEach(p => {
		// if (p.permission === permission) {
		if (this.currentUser.hasPermission(permission)) {
			pass = true;
		}
		// });

		return pass;
	}
}
