// import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Component, Input } from '@angular/core';

import {
	animate,
	trigger,
	state,
	transition,
	style,
} from '@angular/animations';

import { Entity } from '@towncloud/thor-api';

import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CurrentTownService, CurrentUserService } from '../../../../../services/services';

import { User } from '@towncloud/thor-api';

@Component({
	selector: 'submenu',
	templateUrl: './subMenu.html',
	styleUrls: ['./subMenu.scss'],
	// encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('collapseChildTrigger', [
			state('false', style({ height: '0px' })),
			state('undefined', style({ height: '0px' })),
			state('true', style({ height: '*' })),
			transition('* => *', animate('.25s')),
		]),
	],
})
export class SubMenuComponent {
	@Input()
	menu: Array<any>;

	town: any;
	user: User;
	entity: Entity;
	suite: String;
	subscription: Subscription;
	userSub: Subscription;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private currentTownService: CurrentTownService,
		private currentUserService: CurrentUserService,
	) { }

	ngOnInit() {
		this.setTown();

		this.subscription = this.currentTownService
			.getChangeEmitter()
			.subscribe((town) => {
				this.town = town;
			});

		this.userSub = this.currentUserService
			.getChangeEmitter()
			.subscribe((user) => {
				this.setTown();
			});
		
		if (sessionStorage.getItem('entity')) {
			this.entity = JSON.parse(sessionStorage.getItem('entity'));
		}

		if (sessionStorage.getItem('suite')) {
			this.suite = JSON.parse(sessionStorage.getItem('suite'));
		}
	}

	private setTown() {
		this.currentTownService.get().then((town) => {
			if (town) {
				this.town = town;
			}
		});
	}

	public toggleExpand = function (item) {
		// event.preventDefault();
		// console.log(item);
		if (item.expand == undefined) {
			item.expand = true;
		} else {
			if (item.expand == true) {
				item.expand = false;
			} else {
				item.expand = true;
			}
		}
	};

	public navigate = function (event: MouseEvent, item) {
		event.preventDefault();
		// console.log(item);
		this.router.navigate([item.linkPath]);

	};

}
