import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { CurrentUserResource } from '../resources/currentUser.resource';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class PermissionsRouteGuard implements CanActivate {
	currentUserInitialized = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private currentUserResource: CurrentUserResource,
		private toastrService: ToastrService,
	) {

	}

	async canActivate(r) {
		// console.log('@ @ @ @ @ @ can activate - route @ @ @ @ @ @', r);
		if (this.currentUserInitialized == false) {
			// console.log('@ @ @ @ @ @ can activate - initialize @ @ @ @ @ @');
			await this.currentUserResource.init();
			this.currentUserInitialized = true;
		}

		let pass = false;
		// const validToken = this.userTokenService.validToken();
		// console.log('******************* route guard - valid token? - ', validToken);
		if (!this.currentUserResource.validToken()) {
			this.logOut(r);
			pass = false;
		} else {
			// console.log('@ @ @ @ @ @ route guard - current user has permission?');
			if (r.data.permission) {
				pass = this.currentUserResource.hasPermission(r.data.permission)
				// console.log('# # # # # pass - ', pass);
				if (!pass) {
					this.notAuthorized(r);
				}
			} else {
				// no permissions specified
				pass = true;
			}
		}
		return pass;

	}

	notAuthorized(r) {
		console.log('@ @ @ @ @ @ route guard - not authorized');
		if (r.params.town) {
			this.router.navigate(['/', r.params.town, 'notAuthorized']);
		} else {
			this.router.navigate(['/', 'notAuthorized']);
			// this.currentUserResource.set(undefined);
			// sessionStorage.clear();
			// localStorage.clear();
		}

	}

	logOut(r) {
		console.log('@ @ @ @ @ @ route guard - invalid token - logout');
		
		this.toastrService.warning('Your session has expired. Please Log in again to continue.', 'Attention', { timeOut: 0, positionClass: 'toast-top-full-width' });
		
		this.currentUserResource.set(undefined);
		sessionStorage.clear();
		localStorage.clear();

		if (r.params.town) {
			this.router.navigate(['/', r.params.town, 'login']);
		} else {
			this.router.navigate(['/login']);
		}

	}

}
