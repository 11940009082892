import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Field } from '../../../dataTypes/dataTypes';

import { FormService } from '../../../services/services';

import {
	UsersResource,
	ResourceParams,
	User
} from '@towncloud/thor-api';

import { CurrentUserResource } from '../../../resources/resources';

import { CurrentUserService } from '../../../services/services';

@Component({
	templateUrl: './myProfile.html',
	encapsulation: ViewEncapsulation.None
})

export class MyProfileComponent { 

	fields: any = {};
	user: User = new User();
	town: string;
	saveSuccessFull: boolean = false;
	showResetPasswordModal: boolean = false;
	resetPasswordFields: any = {};
	resetSuccessFull: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private usersResource: UsersResource,
		private currentUserResource: CurrentUserResource,
		private formService: FormService
	){
		this.fields.email = new Field({
			type: 'input',
			label: 'Email',
			required: true
		});

		this.fields.firstName = new Field({
			type: 'input',
			label: 'First Name',
			required: true
		});

		this.fields.lastName = new Field({
			type: 'input',
			label: 'Last Name',
			required: true
		});


		this.resetPasswordFields.password = new Field({
			type: 'password',
			label: 'Password',
			required: true
		});

		this.resetPasswordFields.repeatPassword = new Field({
			type: 'password',
			label: 'Repeat Password',
			required: true
		});

	}

	ngOnInit() {
		this.route.params.forEach((params) =>{

			this.town = params['town'];

			this.currentUserResource.get().then(user => {
				this.user = user;
				
				this.loadFormData();
			});
			
		})
	}

	loadFormData(){
		this.fields.email.value = this.user.email;
		this.fields.firstName.value = this.user.firstName;
		this.fields.lastName.value = this.user.lastName;
	}

	fieldChanged(){
		this.saveSuccessFull = false;
		this.resetSuccessFull = false;
	}

	isFormValid(){

		return this.formService.isRequiredFieldsValid(this.fields);
	}

	save(){
		var user = {
			urlModifiers: {
				id: this.user.id
			},
			id: this.user.id,
			email: this.fields.email.value,
			firstName: this.fields.firstName.value,
			lastName: this.fields.lastName.value
		}

		this.usersResource.update( user ).then( data => {

			this.saveSuccessFull = true;
		});
	}


	selectOptionButton(){

		this.showResetPasswordModal = true;

	}

	toggleShowResetPasswordModal(){
		this.showResetPasswordModal = !this.showResetPasswordModal;

		if(this.showResetPasswordModal){

			this.resetPasswordFields.password.value = undefined;

			this.resetPasswordFields.repeatPassword.value = undefined;
		}
	}

	isResetPasswordFormValid(){

		if( this.formService.isRequiredFieldsValid(this.resetPasswordFields) ){

			if( this.resetPasswordFields.password.value == this.resetPasswordFields.repeatPassword.value){
				return true;
			}

		}

		return false;
	}

	resetPassword(){

		var data = {
			urlModifiers: {
				id: this.user.id
			},
			password: this.resetPasswordFields.password.value
		}

		this.usersResource.update( data ).then( results =>{
			this.resetSuccessFull = true;
		})
	}

}