import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CurrentUserResource } from '../../../../resources/currentUser.resource';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
	ResourceParams,
	JobOutputsResource,
	CityApplicationsResource,
	City,
	User,
	ApiService
} from '@towncloud/thor-api';

import {
	Grid,
	Field,
} from '../../../../dataTypes/dataTypes';

import { CurrentTownService } from '../../../../services/services';

@Component({
	templateUrl: './allMyOutputs.component.html',
})

export class AllMyOutputsComponent implements OnInit{
	@ViewChild('downloadLink') private downloadLink: ElementRef;
	grid: Grid = new Grid();
	city: City = new City;
	detailFields: any = {};
	showDetailModal = false;
	currentUser: User;
	cityApplications = [];

	constructor(
		private currentTownService: CurrentTownService,
		private cityApplicationsResource: CityApplicationsResource,
		private jobOutputsResource: JobOutputsResource,
		private currentUserResource: CurrentUserResource,
		private http: HttpClient,
		private apiService: ApiService
	) {
		this.detailFields.number = new Field({
			type: 'input',
			label: 'Job Number',
			disabled: true,
		})

		this.detailFields.name = new Field({
			type: 'input',
			label: 'Job Name',
			disabled: true,
		})

		this.detailFields.status = new Field({
			type: 'input',
			label: 'Job Status',
			disabled: true,
		})

		this.detailFields.messages = new Field({
			type: 'input',
			label: 'Messages',
			disabled: true,
		})
	}

	async ngOnInit() {
		await this.currentUserResource.get().then(user => {
			this.currentUser = user;
		});

		this.cityApplications = await this.getCityApplications();

		this.city = await this.currentTownService.get();

		this.defineGrid();
	}
	
	defineGrid() {
		this.grid = new Grid();
		this.grid.id = 'jobOutputs';
		this.grid.showReports = true;
		this.grid.showDownload = true;

		this.grid.addColumn({
			label: 'Job Number',
			field: 'jobId',
			sortOrder: 'DESC'
		});

		this.grid.addColumn({
			label: 'Job Name',
			field: 'jobName'
		});


		this.grid.addColumn({
			label: 'Application',
			field: 'appName'
		});


		this.grid.addColumn({
			label: 'Job Type',
			field: 'jobType'
		});

		this.grid.addColumn({
			label: 'Job Status',
			field: 'jobStatus'
		});

		this.grid.addColumn({
			label: 'Created',
			field: 'createdAt',
			format: 'datetime'
		});

		// this.grid.addColumn({
		// 	label: 'Completed',
		// 	field: 'completedAt',
		// 	format: 'datetime'
		// });

		this.grid.addColumn({
			label: 'Output',
			field: 'description'
		});

		this.grid.addColumn({
			label: 'Deleted',
			field: 'deleted',
			format: 'yes/no'
		});


		this.grid.addFilter({
			property: 'id',
			operator: ['eq', 'gt', 'gte', 'lt', 'lte'],
			field: new Field({
				type: 'input',
				label: 'Job Number',
			}),
		});

		this.grid.addFilter({
			property: 'jobName',
			operator: 'contains',
			field: new Field({
				type: 'input',
				label: 'Job Name',
			}),
		});


		this.grid.addFilter({
			property: 'jobType',
			operator: 'contains',
			field: new Field({
				type: 'input',
				label: 'Job Type',
			}),
		});


		this.grid.addFilter({
			property: 'jobStatus',
			operator: 'contains',
			field: new Field({
				type: 'select',
				label: 'Job Status',
				options: [
					{
						id: 'Active',
						name: 'Active',
					},
					{
						id: 'Completed-Errors',
						name: 'Completed-Errors',
					},
					{
						id: 'Completed-Success',
						name: 'Completed-Success',
					},
					{
						id: 'Failed',
						name: 'Failed',
					},
					{
						id: 'Queued',
						name: 'Queued',
					},
				],
				optionLabel: 'name'
			}),
		});


		this.grid.addFilter({
			property: 'appId',
			operator: 'eq',
			field: new Field({
				type: 'select',
				label: 'Application',
				options: this.cityApplications,
				optionLabel: 'name'
			})
		});


		this.grid.addFilter({
			property: 'createdAt',
			operator: ['eq', 'gt', 'gte', 'lt', 'lte'],
			field: new Field({
				type: 'date',
				label: 'Created',
			}),
		});


		// this.grid.addFilter({
		// 	property: 'completedAt',
		// 	operator: ['eq', 'gt', 'gte', 'lt', 'lte'],
		// 	field: new Field({
		// 		type: 'date',
		// 		label: 'Completed',
		// 	}),
		// });

		this.grid.addFilter({
			property: 'description',
			operator: 'contains',
			field: new Field({
				type: 'input',
				label: 'Output',
			}),
		});

		this.grid.addFilter({
			property: 'deleted',
			operator: 'eq',
			field: new Field({
				type: 'select',
				label: 'Deleted',
				options: [
					{ value: 'All' },
					{ value: 'Yes', id: true },
					{ value: 'No', id: false },
				],
				optionLabel: 'value'
			})
		});

		this.grid.resource = this.jobOutputsResource;
		this.grid.resourceParams = new ResourceParams({
			filters: [
				[
					{
						property: 'jobCreatedBy',
						operator: 'eq',
						value: this.currentUser.id
					}
				]
			]
		});
	}

	async downloadOutput(e) {
		const params = new ResourceParams({
			$all: true,
			urlModifiers: {
				id: e.row.id,
				file: true,
			},
		});

		this.jobOutputsResource.init(params);
		const ServerWithApiUrl = environment.thorHostName + '/';
		const url = ServerWithApiUrl + this.jobOutputsResource.url;
		const options: any = await this.apiService.getHeader();
		options.responseType = 'arraybuffer';

		const file = await this.http.get(url, options).toPromise();
		console.log(options);
		this.downloadFile(file, e.row.name, 'application/text');
	}

	downloadFile(data: any, name: string, type: string) {
		const blob = new Blob([data], { type: type });
		const url = window.URL.createObjectURL(blob);

		const link = this.downloadLink.nativeElement;
		link.href = url;
		link.download = name;
		link.click();

		window.URL.revokeObjectURL(url);
	}


	async getCityApplications() {
		const town = await this.currentTownService.get();

		const data = new ResourceParams({
			$all: true,
			urlModifiers:{
				cityId: town.id
			}
		})

		return (await this.cityApplicationsResource.get( data )).items;
	}


}