import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
	LoginService,
	CurrentTownService
} from '../../../../services/services';

import {
	CurrentUserResource
} from '../../../../resources/resources';

import { Field } from '../../../../dataTypes/dataTypes';


@Component({
	selector: 'login',
	templateUrl: './login.html',
	styleUrls: ['./login.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
	public email: Field = new Field();
	public password: Field = new Field();
	public passwordResetEmail: Field = new Field();

	public loginFailed = false;
	public showResetModal = false;
	public showRequestModal = false;

	constructor(
		// private router: Router,
		private route: ActivatedRoute,
		private router: Router,
		private currentUser: CurrentUserResource,
		private currentTownService: CurrentTownService,
		private loginService: LoginService
	) {
		this.email = new Field({
			type: 'input',
			label: 'Email',
			id: 'login-email'
		});

		this.password = new Field({
			type: 'password',
			label: 'Password',
			id: 'login-password'
		});

		this.passwordResetEmail = new Field({
			type: 'input',
			label: 'What is your email address?',
			id: 'password-reset-email'
		});

		// ensure any remnant session settings are cleared before new login
		// console.log('=================== Login component ===================');
		sessionStorage.clear();
		localStorage.clear();
		this.currentUser.set(undefined);
	}

	ngOnInit() {

		this.route.queryParams.subscribe(params => {
			if (params['token']) {
				this.showResetModal = true;
			}
		});
	}

	async login() {

		const user = await this.loginService.login(this.email.value, this.password.value);
		// console.log('user', user);
		if(user){
			this.currentUser.set(user);
			// if (!(await this.validateRoute())) {
				const town = this.currentTownService.getTown();
				// console.log(town);
				if (town) {
				  this.router.navigate(['/', town, 'home']);
				} else {
				  this.router.navigate(['/', 'home']);
				}
			// }

		}else{
			this.loginFailed = true;
		}
	}

	updateField(e: any) {
		this.loginFailed = false;
	}
}
