import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from '@towncloud/thor-api';

import { CurrentUserResource } from '../../../../resources/resources';

import { CurrentUserService, CurrentTownService } from '../../../../services/services';

@Component({
	selector: 'view',
	templateUrl: './view.html',
	styleUrls: ['./view.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ViewComponent implements OnInit, OnDestroy{
	isLoggedIn: boolean;
	town: string;
	config: any;
	routeFound: boolean;
	invalidRoute: boolean;
	routePermission: string;
	townInRoute: boolean;
	routeData: any;
	menu: Array<any>;
	// permissions: Array<any> = [];
	applications: Array<any> = [];
	userSubscription: Subscription;
	routerSubscription: Subscription;
	selectedSuite: string;

	user: User = new User();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private currentUser: CurrentUserResource,
		private currentUserService: CurrentUserService,
		private currentTownService: CurrentTownService
	) {
		this.getCurrentUser();

		this.routerSubscription = router.events.subscribe((event) => {
			
			if ((this.config = this.router.routerState.root.firstChild.firstChild)) {
				this.config = this.router.routerState.root.firstChild.firstChild.routeConfig.data;
			} else {
				this.config = this.router.routerState.root.firstChild.routeConfig.data;
			}
			
			if (this.config) {
				this.routePermission = this.config.permission;
			} else {
				this.routePermission = undefined;
			}
		});

		this.userSubscription = this.currentUserService
			.getChangeEmitter()
			.subscribe(async (user) => {
				this.getCurrentTown();
				this.getCurrentUser();
			});
	}

	ngOnInit() {
		this.getCurrentTown();
	}

	ngOnDestroy() {
		this.userSubscription.unsubscribe();
		this.routerSubscription.unsubscribe();
	}

	
	routeExists() {
		if (this.town == undefined && this.townInRoute) {
			return false;
		}

		return true;
	}

	hasPermission() {
		// if (this.permissions && this.routePermission != undefined) {
		console.log(' & & & & view - hasPermission & & & & &');
		if (this.routePermission != undefined) {
			let result = false;
			// this.permissions.forEach((item) => {
			// if (item.permission == this.routePermission) {
			if (this.currentUser.hasPermission(this.routePermission)) {	
				result = true;
				return result;
			}
			return result;
			}
			// );

			return true;
		}

	getCurrentTown() {
		this.currentTownService.get().then((town) => {
			if (town) {
				this.town = town.slug;
			}
		});
	}

	getCurrentUser() {
		// this.permissions = [];

		this.currentUser.get().then((user) => {
			this.user = user;

			if (!this.user.id) {
				return false;
			}

			// this.currentUser.getPermissions().then((permissions: any) => {
			// 	this.permissions = permissions.items;
			// });
		});
	}

	onUserChange() {
		this.getCurrentUser();
	}
}
