import { Component, Input, ViewChild, ElementRef } from '@angular/core';
// import { ChartOptions } from 'chart.js';

@Component({
  selector: 'actualsBarChart',
  templateUrl: 'actualsBarChart.component.html',
  styleUrls: ['actualsBarChart.component.css']
})
export class ActualsBarChartComponent {
  @ViewChild('barChart', {static: false}) myCanvas: ElementRef;
  @Input() barChartData: any;
  @Input() barChartLabels: any;
  @Input() barChartTitle: string;
 
  // public lineChartOptions: ChartOptions = {
  //   title: {
  //     display: true,
  //     text: 'World population per region (in millions)'
  //   }
  // }

  
  public barChartType = "'bar'";
  public barChartLegend = true;
  public context: any;

  constructor() {console.log('ActualsBarChartComponent')}

}
