import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
	ActivatedRoute,
	NavigationEnd,
	Router
} from '@angular/router';
import { Subscription } from 'rxjs';
// import 'rxjs/add/operator/map';
// import { AppResolverService } from '../../../../services/appResolver.service';
import {
	ApiService,
	City,
	CityApplicationsResource,
	EntitiesResource,
	Entity,
	ResourceParams,
	User,
	UsersResource,
} from '@towncloud/thor-api';
import { Field } from '../../../../dataTypes/dataTypes';

import { CurrentUserResource, InfoRequestResource } from '../../../../resources/resources';
import {
	CurrentTownService,
	CurrentUserService,
	FinancialsAccountConfigService,
	FormService,
	MenuService,
} from '../../../../services/services';

@Component({
	selector: 'tc-body',
	templateUrl: './tc-body.html',
	styleUrls: ['./tc-body.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TcBodyComponent implements OnInit, OnDestroy{

	isLoggedIn: boolean;
	townInRoute: boolean;

	townSubscription: Subscription;
	menuSubscription: Subscription;
	userSubscription: Subscription;

	config: any;
	name: string;
	title: string;
	subTitle: string;
	selectedSuite: string;
	breadCrumbs: Array<any>;
	user: User = new User();
	selectedEntity: Entity;

	private sub: any;

	town: City;

	menu: Array<any>;
	commonMenu: Array<any>;
	suite: string;
	unfilterdMenu: Array<any> = [];
	masterMenu: Array<any> = [];
	applications: Array<any> = [];

	showNameMenu = false;
	showSuitesMenu = false;
	entityRequired = false;
	showLeftMenu = true;
	showAdmin = false;

	showHelp = false;
	showOutputs = false;

	entitySelection: any;

	showEntitySelector = false;
	showResetPasswordModal = false;
	resetPasswordFields: any = {};
	resetSuccessFull = false;
	loggingOut = false;

	infoRequestMessage: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private currentUser: CurrentUserResource,
		private usersResource: UsersResource,
		private cityApplicationsResource: CityApplicationsResource,
		private entitiesResource: EntitiesResource,
		private menuService: MenuService,
		private currentUserService: CurrentUserService,
		private currentTownService: CurrentTownService,
		private accountConfigService: FinancialsAccountConfigService,
		private formService: FormService,
		private infoRequestResource: InfoRequestResource,
	) {
		
		this.entitySelection = new Field({
			type: 'select',
			placeholder: 'select entity',
			options: [],
			optionLabel: 'name'
		});

		this.resetPasswordFields.password = new Field({
			type: 'password',
			label: 'Password',
			required: true,
		});

		this.resetPasswordFields.repeatPassword = new Field({
			type: 'password',
			label: 'Repeat Password',
			required: true,
		});

	}

	async ngOnInit() {
		// console.log('============= ngOnInit ================');
		// console.log(localStorage.getItem('accessToken'));
		// console.log(localStorage.getItem('user'));
		

		if (localStorage.getItem('accessToken')) {
			// Check if existing token is valid. Token may exists in localStorage if user simply closed the browser without logging out
			if (this.currentUser.validToken()) {
				const accessToken = JSON.parse(localStorage.getItem('accessToken'));
				ApiService.setToken(accessToken.token);
			} else {
				// console.log('================= invalid token - log out ===================');
				this.loggingOut = true;
				this.logOut();
			}	
		} 

		this.townSubscription = this.currentTownService
		.getChangeEmitter()
		.subscribe(async (town) => {
			// console.log('================ body - town event =================');
			// console.log(town);
			if (town) {
				this.town = town
				this.townInRoute = true;
				if (localStorage.getItem('user')) {
					// console.log('================ town event init =================');
					await this.init();
				}
				
			} else {
				this.townInRoute = false;
				if (localStorage.getItem('user')) {
					// console.log('================ !town event init =================');
					await this.init();
				}
			}
		});

		if (sessionStorage.getItem('entity')) {
			this.selectedEntity = JSON.parse(sessionStorage.getItem('entity'));
			ApiService.setEntity(this.selectedEntity.id.toString());
			this.accountConfigService.setEntity(this.selectedEntity);
		}

		this.userSubscription = this.currentUserService
			.getChangeEmitter()
			.subscribe(async (user) => {
				// console.log('================ body - user event =================');
				// console.log(user);
				if (!localStorage.getItem('accessToken') && !this.loggingOut)  {
					// console.log('================ user event logging out =================');
					this.loggingOut = true;
					this.logOut();
				} else {
					// console.log('================ user event init =================');
					await this.init();
				}
			});

		this.router.events.subscribe(async (r) => {
			if (r instanceof NavigationEnd) {
				// console.log(localStorage.getItem('accessToken'));
				// console.log('================ body - router event - NavigationEnd =================',r);
				if (!localStorage.getItem('accessToken'))  {
					// console.log('================ router event logging out =================');
					this.loggingOut = true;
					this.logOut();
				} else {
					// console.log('================ router event init =================');
					await this.init();
				}
			}
		});
		
	}

	ngOnDestroy() {
		// this.menuSubscription.unsubscribe();
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
		if (this.townSubscription) {
			this.townSubscription.unsubscribe();
		}
	}

	async init() {
		// console.log('============ init ===============');
		await this.setCurrentUser();
		await this.getApplications();
		await this.getEntities();
		await this.initSuite();
		await this.initEntity();
	}



	async setCurrentUser() {
		// console.log('# # # # # body component - setCurrentUser # # # # #', this.masterMenu.length);
		const newUser = await this.currentUser.get();
		
		if (!newUser.id) {
			this.user = new User();
			return false;
		}
		if (newUser.id && this.user.id !== newUser.id) {
			this.user = newUser;
			if (this.masterMenu.length === 0) {
				try {
					this.masterMenu = await this.menuService.getMain();
				} catch (e) {
					console.log('% % % % error getting menu % % % %', e);
				}
			}
			
			this.unfilterdMenu = JSON.parse(JSON.stringify(this.masterMenu));

			if (this.user.passwordResetRequired) {
				this.showResetPasswordModal = true;
			}
		}
	}

	async getApplications() {
		if (!this.town) {
			return false;
		}

		const data = new ResourceParams({
			$all: true,
			urlModifiers: {
				cityId: this.town.id,
			},
		});

		this.applications = (await this.cityApplicationsResource.get(data)).items;
	}

	async getEntities() {
		// console.log('=============== get entities ==============');
		
		if (!this.town) {
			return false;
		}
		// console.log(this.entitySelection.options);
		if (this.entitySelection.options.length === 0 ) {
			const data = new ResourceParams({
				$all: true,
			});

			await this.entitiesResource.get(data).then(entities => {
				// console.log(entities);	
				this.entitySelection.options = entities.items;
			});
		}

		if (sessionStorage.getItem('entity')) {
			this.entitySelection.value = JSON.parse(sessionStorage.getItem('entity'));
		} else {
			this.entitySelection.value = undefined;
		}
	}

	async initSuite() {
		// console.log('================ initSuite ================');
		this.showAdmin = false;
		let suiteFromUrl = '';
		
		if (!this.townInRoute && this.router.url == '/Admin') {
			suiteFromUrl = 'Admin'
		} else {
			const urlSegments = this.router.routerState.snapshot.url.split('/');
			// console.log(urlSegments);
			if (urlSegments.length > 2) {
				if (this.townInRoute) {
					suiteFromUrl = urlSegments[2];
				} else {
					suiteFromUrl = urlSegments[1];
				}
			}	
		}
		// console.log('============ suiteFromUrl = ' + suiteFromUrl);
		if (suiteFromUrl && suiteFromUrl !== 'notAuthorized' && suiteFromUrl !== 'home' && suiteFromUrl !== 'myProfile'  && suiteFromUrl !== 'allMyOutputs') {
			if ((!sessionStorage.getItem('suite')) || (this.selectedSuite !== suiteFromUrl)) {
				this.selectedSuite = suiteFromUrl;
				sessionStorage.setItem('suite', JSON.stringify(this.selectedSuite));
				
			}
		}

		if (sessionStorage.getItem('suite')) {
			this.selectedSuite = JSON.parse(sessionStorage.getItem('suite'));
			if (this.selectedSuite === 'Admin') {
				this.showAdmin = true;
			}
			this.checkIfEntityRequired();
			this.toggleShowEntitySelector();
			this.buildMenu();
		}
	}

	async initEntity() {
		// console.log('================ initEntity ================');
		// console.log(this.router.routerState.snapshot.url);
		// console.log(this.route);
		if (this.selectedSuite === 'Finance') {
			let entityIdFromUrl = '';
			const urlSegments = this.router.routerState.snapshot.url.split('/');
			// console.log(urlSegments);
			if (urlSegments.length > 3) {
				entityIdFromUrl = urlSegments[3];
			} 
			if (this.entitySelection.options.length === 0) {
				this.getEntities();
			}
			if (entityIdFromUrl && !sessionStorage.getItem('entity')) {
				this.selectedEntity = this.entitySelection.options.find(item => item.id == entityIdFromUrl);
				this.entitySelection.value = this.selectedEntity;
				sessionStorage.setItem('entity', JSON.stringify(this.selectedEntity));
				ApiService.setEntity(this.selectedEntity.id.toString());
				this.accountConfigService.setEntity(this.selectedEntity);
			}
			this.checkIfEntityRequired();
			this.showSuitesMenu = false;	
		}	
	}

	setEntity() {
		this.selectedEntity = this.getSelectedEntity();
		// console.log('============= set entity ==============');
		// console.log(this.selectedEntity);
		if (this.selectedEntity) {
			// this.initEntity();
			sessionStorage.setItem('entity', JSON.stringify(this.selectedEntity));
			ApiService.setEntity(this.selectedEntity.id.toString());
			this.accountConfigService.setEntity(this.selectedEntity);
			this.checkIfEntityRequired();
			// when entity changes navigate to financials home page for selected entity
			// hack to resolve navigation error - Cannot activate an already activated outlet
			this.router.navigateByUrl('/home').then(() => {
				this.router.navigate(['/', this.town.slug, 'Finance', this.selectedEntity.id]);
			  });
			// this.router.navigate(['/', this.town.slug, 'Finance', this.selectedEntity.id, 'financeHome']);
			// this.router.navigate([ 'Finance', this.selectedEntity.id, 'financeHome'], {relativeTo: this.route}) ;
		} else {
			sessionStorage.removeItem('entity');
		}

	}

	getSelectedEntity() {
			if (this.entitySelection.value) {
				return this.entitySelection.value;
			}
			return null;
	}

	toggleShowResetPasswordModal() {
		this.showResetPasswordModal = !this.showResetPasswordModal;

		if (this.showResetPasswordModal) {
			this.resetPasswordFields.password.value = undefined;

			this.resetPasswordFields.repeatPassword.value = undefined;
		}
	}

	isResetPasswordFormValid() {
		if (this.formService.isRequiredFieldsValid(this.resetPasswordFields)) {
			if (
				this.resetPasswordFields.password.value ===
				this.resetPasswordFields.repeatPassword.value
			) {
				return true;
			}
		}

		return false;
	}

	resetPassword() {
		const data = {
			urlModifiers: {
				id: this.user.id,
			},
			id: this.user.id,
			password: this.resetPasswordFields.password.value,
		};

		this.usersResource.update(data).then((results) => {
			this.currentUser.set(results);

			this.resetSuccessFull = true;
			this.user.passwordResetRequired = false;
		});
	}

	fieldChanged() {
		this.resetSuccessFull = false;
	}

	toggleShowLeftMenu() {
		this.showLeftMenu = !this.showLeftMenu;
	}

	hideShowLeftMenu() {
		this.showLeftMenu = false;
	}


	hideShowNameMenu() {
		this.showNameMenu = false;
	}

	toggleShowNameMenu() {
		this.showNameMenu = !this.showNameMenu;
	}

	hideShowHelp() {
		this.showHelp = false;
	}

	toggleShowHelp() {
		this.showHelp = !this.showHelp;
	}

	toggleShowOutputs() {
		this.showOutputs = !this.showOutputs;
	}

	hideShowSuitesMenu() {
		this.showSuitesMenu = false;
	}

	toggleShowSuitesMenu() {
		this.showSuitesMenu = !this.showSuitesMenu;
	}
e
	closeSuitesMenu() {
		this.showSuitesMenu = false;
	}

	closeOutputs() {
		this.showOutputs = false;
	}

	setSuite(suiteName) {
		this.showAdmin = false;
		this.selectedSuite = suiteName;
		this.showEntitySelector = false;
		this.infoRequestMessage = '';
		if (this.selectedSuite === 'Admin') {
			this.showAdmin = true;
		}
		if (this.selectedSuite) {
			sessionStorage.setItem('suite', JSON.stringify(this.selectedSuite));
			this.toggleShowEntitySelector();
		// save in local storage to assist in opening links in new tabs or windows 
			// localStorage.setItem('tempSuite', JSON.stringify(this.selectedSuite));
			
		} else {
			sessionStorage.removeItem('suite');
		}

		this.checkIfEntityRequired();
		this.buildMenu();

		this.showSuitesMenu = false;
		
	}

	async moreInfo() {
		const params = {
			suite: this.selectedSuite,
			city: this.town,
			userFirstName: this.user.firstName,
			userLastName: this.user.lastName
		};

		await this.infoRequestResource.post(params);
		this.infoRequestMessage = 'Thank you for your interest. We will follow up soon. In the mean time, select another suite to continue.';
	}

	checkIfEntityRequired() {
		if (!sessionStorage.getItem('entity') && this.selectedSuite === 'Finance') {
			this.entityRequired = true;
		} else {
			this.entityRequired = false;
		}
		// console.log('=========== check if entity require - ' , this.entityRequired);
	}

	toggleShowEntitySelector() {
		if (this.selectedSuite === 'Finance') {
			this.showEntitySelector = true;
		} else {
				this.showEntitySelector = false;
			}
	}

	logOut() {
		// console.log('==================== logOut ===============');
		this.currentUser.set(undefined);
		this.user = new User();
		sessionStorage.clear();
		localStorage.clear();
		this.showNameMenu = false;
		this.selectedSuite = '';
		this.entityRequired = false;
		this.showEntitySelector = false;
		this.entitySelection.value = undefined;	
		this.loggingOut = false;

		if (this.town && this.town.slug) {
			this.router.navigate(['/', this.town.slug, 'login']);
		} else {
			this.router.navigate(['/login']);
		}
	}

	myProfile() {
		if (this.town && this.town.slug) {
			this.router.navigate([this.town.slug, 'myProfile']);
		} else {
			this.router.navigate(['myProfile']);
		}
	}

	buildMenu() {
		// create suite menu (menu) and common menu
		// console.log('============== build menu =============');
		// console.log(localStorage.getItem('user'));
		// console.log(sessionStorage.getItem('suite'));
		this.menu = this.menuFilter(this.unfilterdMenu, (JSON.stringify(this.selectedSuite)));
		// console.log('# # # # # # menu - ', this.menu);
		if (this.selectedSuite !== 'Admin') {
			// console.log('# # # # suite != Admin - ', this.selectedSuite);
			this.commonMenu = this.menuFilter(this.unfilterdMenu, (JSON.stringify('Common')));
		}
		
	}

	menuFilter(menu, suite) {
		// console.log('% % % % body - menuFilter % % % % % %');
		// console.log(menu);
		// console.log(suite);

		if (!menu) {
			return [];
		}

		const newMenu = menu.filter((item) => {
			// checking required town
			if (item.requires_town === true && this.town === undefined) {
				return false;
			}

			if (!this.hasApplication(this.applications, item)) {
				return false;
			}

			// check for items in selected suite
			if (item.suite) {
				if (!(JSON.stringify(item.suite) === suite) ) {
					return false;
				}
			}

			// set path variables
			if (item.path) {
				item.linkPath = this.menuService.setPathlinks(item);
			}

			// checking permissions
			if (item.permission) {	
				let result = false;
	
				if (this.currentUser.hasPermission(item.permission)) {
					result = true;
					return true;
				}
	

				if (item.list) {

					item.list = this.menuFilter([...item.list], suite);
					
					if (item.list.length === 0) {
						result = false;
					}
				}

				return result;
			}

			if (item.list) {

				item.list = this.menuFilter([...item.list], suite);

				if (item.list.length === 0) {
					return false;
				}
			}

			return true;
		});
		
		return newMenu;
	}

	hasApplication(applications, item) {
		if (!item.application) {
			return true;
		}

		let found = false;

		applications.forEach((app) => {
			if (item.application === app.name) {
				found = true;
				return true;
			}
		});
		
		return found;
	}
}
