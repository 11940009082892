
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { appRoutingProviders, routing } from './app.routing';

import { ThorApiModule } from '@towncloud/thor-api';
import { MenuService } from '../../services/menu.service';

import { CoreModule } from '../core/core.module';

import { TcBodyComponent } from './components/body/tc-body.component';
import { ForgotPasswordComponent } from './components/forgotPassword/forgotPassword.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/passwordReset/passwordReset.component';

import { MyProfileComponent } from '../admin/myProfile/myProfile.component';
import { BillingHomeComponent } from '../billing/home/billingHome.component';
import { HomeComponent } from '../home/home.component';
import { NoFoundComponent } from './components/404/404.component';
import { AppComponent } from './components/app.component';

import { environment } from '../../environments/environment';
import { TcHelpService } from '../../services/tc-help.service';
import { HelpComponent } from './components/help/help.component';
import { CurrentTownService } from '../../services/currentTown.service';
import { MyOutputsComponent } from './components/myOutputs/myOutputs.component';
import { AllMyOutputsComponent } from './components/allMyOutputs/allMyOutputs.component';
// import { PermissionsRouteGuard } from '../../services/permissionsRouteGuard.service';

export function appInitializer(currentTownService: CurrentTownService) {
	return () => new Promise<void>(async (resolve, reject) => {
	  const pathArray = window.location.pathname.split('/');
	  const town = pathArray[1]; // Assuming the URL is like `site.com/:slug`
  
	  if (town && town != 'Admin' && town != 'login') {
		// console.log('================ app module - url - ', town);
		await currentTownService.setTown(town);
		// console.log(currentTownService.getTown());
		resolve();
	  } else {
		resolve();
	  }
	});
}
@NgModule({

	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({closeButton: true, preventDuplicates: true}),
		routing,
		HttpClientModule,
		FormsModule,
		CoreModule,
		ThorApiModule.forRoot({ thorHostName: environment.thorHostName })
		
	],

	declarations: [
		LoginComponent,
		TcBodyComponent,
		AppComponent,
		NoFoundComponent,
		ForgotPasswordComponent,
		HomeComponent,
		BillingHomeComponent,
		MyProfileComponent,
		PasswordResetComponent,
		HelpComponent,
		MyOutputsComponent,
		AllMyOutputsComponent
	],

	providers: [
		// PermissionsRouteGuard,
		CurrentTownService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			deps: [CurrentTownService],
			multi: true
		},
		appRoutingProviders,
		TcHelpService,
		HelpComponent,
	],

	bootstrap: [AppComponent],
})

export class AppModule {
	constructor(
		private menuService: MenuService
	){
	}
}
