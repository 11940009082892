import { DataType } from "./dataType";
import { Grid } from "./grid";

export class Field extends DataType {
	type: string;
	id: string;
	label: string;
	value: any;
	placeholder: string;
	disabled: boolean;
	required: boolean;
	showOptions: boolean;
	min: number;
	max: number;
	step: number;
	precision: number;
	cols: number;
	rows: number;
	mask: string;

	options: Array<Object>;
	optionLabel: string;

	grid: Grid;
	lookupLabel: any;

	//file type
	maxCount: number;
	maxHeight: number;
	maxWidth: number;
	maxFileSize: number;
	displayType: string;
	fileTypes: string[];
	preUpload: boolean;

	filelds: Array<Field>;

	resource: any;
	resourceParams: any;
	filters: String[];
	invalid: boolean;

	constructor(object: Object = undefined) {
		var properties = [
			"type",
			"id",
			"label",
			"options",
			"optionLabel",
			"value",
			"placeholder",
			"disabled",
			"required",
			"showOptions",
			"grid",
			"lookupLabel",
			"min",
			"max",
			"step",
			"precision",
			"rows",
			"cols",
			"mask",
			"maxCount",
			"maxHeight",
			"maxWidth",
			"maxFileSize",
			"displayType",
			"fileTypes",
			"preUpload",
			"fields",
			"resource",
			"resourceParams",
			"filters",
			"invalid"
		];
		super(object, properties);
	}
}
