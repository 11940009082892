import { Injectable, } from '@angular/core';

import { Field } from '../../dataTypes/dataTypes';
import {
	AttendeeRolesResource,
	ResourceParams
} from '@towncloud/thor-api';

@Injectable()
export class AttendeeRolesGrid {

	constructor(
		private attendeeRolesResource: AttendeeRolesResource
	) {}

	settings() {
		return {
			columns: [
				{
					label: 'Name',
					field: 'name'
				},
				{
					label: 'Notify When Published',
					field: 'notifyWhenPublished',
					type: 'checkbox',
					disabled: true
				},
				{
					label: 'Notify When Packet Generated',
					field: 'notifyWhenPacketGenerated',
					type: 'checkbox',
					disabled: true
				},
				{
					label: 'Notify When Post Meeting',
					field: 'notifyPostMeeting',
					type: 'checkbox',
					disabled: true
				}
			],
			filters: [
				{
					property: 'name',
					operator: 'contains',
					field: new Field({
						type: 'input',
						label: 'Name'
					})
				},
				{
					property: 'notifyWhenPublished',
					operator: 'eq',
					field: new Field({
						type: 'checkbox',
						label: 'Notify When Published',
						id: 'notifyWhenPublished'
					})
				},
				{
					property: 'notifyWhenPacketGenerated',
					operator: 'eq',
					field: new Field({
						type: 'checkbox',
						label: 'Notify When Packet Generated',
						id: 'notifyWhenPacketGenerated'
					})
				},
				{
					property: 'notifyPostMeeting',
					operator: 'eq',
					field: new Field({
						type: 'checkbox',
						label: 'Notify When Post Meeting',
						id: 'notifyPostMeeting'
					})
				}
			],
			resource: this.attendeeRolesResource,
			resourceParams: new ResourceParams({
				sort: [{
					field: 'name',
					order: 'ASC'
				}]
			})
		};
	}
}
