import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PasswordResource } from '../../../../resources/resources';

import { Field } from '../../../../dataTypes/dataTypes';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgotPassword.html'
})
export class ForgotPasswordComponent {
  public email: Field = new Field();
  public resetMessage: string;

  constructor(private router: Router, private route: ActivatedRoute, private passwordResource: PasswordResource) {
    this.email = new Field({
      type: 'input',
      label: 'What is your email address?',
      id: 'email'
    });
  }

  async requestEmail() {
    const params = {
      email: this.email.value
    };

    await this.passwordResource.post(params);
    this.resetMessage = `If we have an account with ${this.email.value}, instructions have been sent.`;
    this.email.value = undefined;
  }
}
