import { Injectable } from '@angular/core';
import { 
	Entity, 
	FinancialsAccountConfigsResource, 
	FinancialsAccountConfig,
	ResourceParams } 
	from '@towncloud/thor-api';

@Injectable({
  providedIn: 'root',
})

export class FinancialsAccountConfigService {
	private instanceId: number;
	private entity: Entity;
	private accountConfig: FinancialsAccountConfig;
	private fundDefault = 'Fund';
	private deptDefault = 'Department';
	private divDefault = 'Division';
	private objDefault = 'Object';
	private funcDefault = 'Function';
	private projDefault = 'Project';
	private numberSuffix = ' Number';
	private descSuffix = ' Description'


	constructor(
		private accountConfigsResource: FinancialsAccountConfigsResource,
	) {
		this.instanceId = Date.now(); // Assign a unique ID based on the current timestamp
		// console.log('FinancialsAccountConfigService');
		// console.log('Instance ID:', this.getInstanceId());
	}

	setEntity(entity) {
		// console.log('$$$$$$$$$$$$$$$$$$$$$ Set Entity $$$$$$$$$$$$$$$$$$$$$');
		if (this.entity != entity) {
			this.getEntity();
			this.getAccountConfig();
		}
		
	}

	getInstanceId(): number {
		return this.instanceId;
	}

	getEntity() {
		if (sessionStorage.getItem('entity')) {
			this.entity = JSON.parse(sessionStorage.getItem('entity'));
		}
	}

	getAccountConfig() {
		// console.log('%%%%%%%%%%%%%%%%%%% getAccountConfig %%%%%%%%%%%%%%%%%%%');
		this.getEntity();
		if (this.entity.id) {
			const params = new ResourceParams({
				filters: [
					[
						{
							property: 'owningEntityId',
							operator: 'eq',
							value: this.entity.id,
						},
					],
				],
			});
	
			this.accountConfigsResource.get(params).then(data => {
				this.accountConfig = data.items[0];
				// console.log(this.accountConfig);
			});
		}
		
	}

	fundSeparator() {
		return this.accountConfig.fundSeparatorCharacter
	}

	departmentSeparator() {
		return this.accountConfig.deptSeparatorCharacter
	}

	divisionSeparator() {
		return this.accountConfig.divSeparatorCharacter
	}

	departmentSegmentInUse() {
		if (this.accountConfig) {
			return this.accountConfig.deptUseSegment
		} else {
			return false;
		}
	}

	departmentLength() {
		return this.accountConfig.deptLength
	}

	divisionSegmentInUse() {
		if (this.accountConfig) {
			return this.accountConfig.divUseSegment
		} else {
			return false;
		}
	}

	functionSegmentInUse() {
		if (this.accountConfig) {
			return this.accountConfig.funcUseSegment
		} else {
			return false;
		}
	}

	projectSegmentInUse() {
		if (this.accountConfig) {
			return this.accountConfig.projUseSegment
		} else {
			return false;
		}
	}

	divisionLength() {
		return this.accountConfig.divLength
	}

	fundLabel() {
		return this.getLabel('fund', 'none');
	}

	fundNumberLabel() {
		return this.getLabel('fund', 'num');
	}

	fundDescriptionLabel() {
		return this.getLabel('fund', 'desc');
	}

	departmentLabel() {
		return this.getLabel('dept', 'none');
	}

	departmentNumberLabel() {
		return this.getLabel('dept', 'num');
	}

	departmentDescriptionLabel() {
		return this.getLabel('dept', 'desc');
	}

	divisionLabel() {
		return this.getLabel('div', 'none');
	}

	divisionNumberLabel() {
		return this.getLabel('div', 'num');
	}

	divisionDescriptionLabel() {
		return this.getLabel('div', 'desc');
		
	}

	objectLabel() {
		return this.getLabel('obj', 'none');
	}

	objectNumberLabel() {
		return this.getLabel('obj', 'num');
	}

	objectDescriptionLabel() {
		return this.getLabel('obj', 'desc');
	}

	functionLabel() {
		return this.getLabel('func', 'none');
	}

	functionNumberLabel() {
		return this.getLabel('func', 'num');
	}

	functionDescriptionLabel() {
		return this.getLabel('func', 'desc');
	}

	projectLabel() {
		return this.getLabel('proj', 'none');
	}

	projectNumberLabel() {
		return this.getLabel('proj', 'num');
	}

	projectDescriptionLabel() {
		return this.getLabel('proj', 'desc');
	}

	getLabel(segment, labelType) {
		let suffix;
		let prefix;
		switch (labelType) {
			case 'num':
				suffix = this.numberSuffix;
				break;
			case 'desc':
				suffix = this.descSuffix;
				break;
			default:
				suffix = '';
		}
		switch (segment) {
			case 'fund':
				prefix = this.fundDefault;
				if (this.accountConfig && this.accountConfig.fundAltLabel) {
					prefix = this.accountConfig.fundAltLabel
				}
				break;
			case 'dept':
				prefix = this.deptDefault;
				if (this.accountConfig && this.accountConfig.deptAltLabel) {
					prefix = this.accountConfig.deptAltLabel
				}
				break;
			case 'div':
				prefix = this.divDefault;
				if (this.accountConfig && this.accountConfig.divAltLabel) {
					prefix = this.accountConfig.divAltLabel
				}
				break;
			case 'obj':
				prefix = this.objDefault;
				if (this.accountConfig && this.accountConfig.objAltLabel) {
					prefix = this.accountConfig.objAltLabel
				}
				break;
			case 'func':
				prefix = this.funcDefault;
				if (this.accountConfig && this.accountConfig.funcAltLabel) {
					prefix = this.accountConfig.funcAltLabel
				}
				break;
			case 'proj':
				prefix = this.projDefault;
				if (this.accountConfig && this.accountConfig.projAltLabel) {
					prefix = this.accountConfig.projAltLabel
				}
				break;
			}
		return prefix + suffix;	
	}	
}
