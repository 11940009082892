import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '../environments/environment';
import { Console } from 'console';

// import { debug } from 'console';
// import { Hash } from 'crypto';
@Injectable({
	providedIn: 'root',
})
export class TcHelpService {
	constructor(private httpClient: HttpClient) { }

	private apiUrl = '';
	private perPage = '4';
	private startPage = '1';

	private username = environment.tcHelpUser;
	private password = environment.tcHelpPassword;

	private htkbCategories: any = {};
	private cityHelpCategories: Array<any> = [];

	// category path retrieves all ht-kb-categories under the townclowd.app (21) parent category
	private categoryPath = `https://www.towncloud.help/wp-json/wp/v2/ht-kb-category?parent=21`

	// base path retrieves ht-kb knowledgebase articles, 
	// limited to those associated with towncloud.app (ht-kb-category = 21)
	// returned in descending published date order (filter[orderby]=date&?order=desc)
	private basePath = `https://www.towncloud.help/wp-json/wp/v2/ht-kb?ht-kb-category=21&filter[orderby]=date&order=desc`;

	// results can be further limited with additional parameters
	// for and conitions, parameter values are added individually
	// for or conditions, parameter can be added once with multiple values
	// the same parameter can be added multiple times
	// In addition to the towncloud.app category, there are 2 other categories shared amongst all customers 
	//     Whats new
	private whatsNewParam = '';
	//     Common Features
	// private commonFeaturesParam = '&ht-kb-category=186';
	// // Remaining categories are associated with and limited by the applications a customer has subscribed to 
	private allCustomerAppsParam = '&ht-kb-category=';
	// 
	private searchParam = '';
	private searchCategoriesParam = '';

	// the Wordpress site requires authentication both on the frentend (website) an through the API
	// we are using Basic Authentication with user & password stored as env variables
	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + btoa(`${this.username}:${this.password}`)
		})
	};
	
	public async getCityHelpCategories(cityApplications) {
		if(this.cityHelpCategories.length > 0) return this.cityHelpCategories;
		
		await this.getHtkbCategories();

		if (this.htkbCategories.length === 0) return;

		for (let i = 0; i < this.htkbCategories.length; i++) {
			let category = this.htkbCategories[i];
	
			if (category.acf.is_whats_new) {
				// there should be just 1 category flagged as 'Whats New'
				this.whatsNewParam = '&ht-kb-category=' + category.id.toString();
			} else {
				if (!category.acf.thor_application_id) {
					// no application id would represent categories like 'Common Features' which are available to all customers
					this.cityHelpCategories.push(category);
					this.allCustomerAppsParam = this.allCustomerAppsParam + category.id.toString() + ",";
				} else{ 
					//  these are apps subscribed to by customers and found in the postgres city_applications table

					if (cityApplications.some(e => e.id === category.acf.thor_application_id)) {	
						this.cityHelpCategories.push(category);
						this.allCustomerAppsParam = this.allCustomerAppsParam + category.id.toString() + ",";
					};
				};
			};
		};
		// console.log("tc help svc - getCityHelpCategories");
		// console.log(this.cityHelpCategories);
		return this.cityHelpCategories;
	}

	public getHtkbCategories() {
		return this.httpClient
			.get(`${this.categoryPath}`, this.httpOptions)
			.toPromise()
			.then((res: any) => {
				this.htkbCategories = res;
			});	
	}

	public getWhatsNew() {
		return this.httpClient
			.get(`${this.basePath}${this.whatsNewParam}${this.allCustomerAppsParam}`, this.httpOptions)
			.pipe(retry(2), catchError(this.handleError));
	}

	public getHelpArticles(searchValues) {
		this.buildSearchParam(searchValues);
		return this.httpClient
			.get(`${this.basePath}${this.whatsNewParam}${this.allCustomerAppsParam}${this.searchParam}`, this.httpOptions)
			.pipe(retry(2), catchError(this.handleError));
	}

	public getHelpArticlesByCategory(searchCategories) {
		this.buildSearchCategoryParam(searchCategories);
		return this.httpClient
			.get(`${this.basePath}${this.searchCategoriesParam}`, this.httpOptions)
			.pipe(retry(2), catchError(this.handleError));
	}
	
	private buildSearchParam (searchValues) {
		this.searchParam = '&search=' + searchValues.toString();
	}

	private buildSearchCategoryParam (searchCategories) {
		this.searchCategoriesParam = '&ht-kb-category=' + searchCategories;
	}

	public nextPage(page: number) {
		return this.httpClient
			.get(`${this.basePath}?page=${page}&per_page=${this.perPage}&?filter[orderby]=date&order=desc`, this.httpOptions)
			.pipe(retry(2), catchError(this.handleError));
	}

	public previousPage(page: number) {
		return this.httpClient
			.get(`${this.basePath}?page=${page}&per_page=${this.perPage}&?filter[orderby]=date&order=desc`, this.httpOptions)
			.pipe(retry(2), catchError(this.handleError));
	}



	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// Client error
			console.error('An error occurred:', error.error.message);
		} else {
			// backend error
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// observable error message
		return throwError(() => 'Something bad happened; please try again later.');
	}

}
