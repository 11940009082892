import { Injectable, } from '@angular/core';

import { Grid } from '../dataTypes/dataTypes';

import { UsersGrid } from './grids/users.grid';
import { AttendeeRolesGrid } from './grids/attendeeRoles.grid';

@Injectable()
export class GridService {

	constructor(
		private usersGrid: UsersGrid,
		private attendeeRolesGrid: AttendeeRolesGrid
	) {
	}

	getSettings(grid, config: any = {}) {

		grid = eval('this.' + grid + 'Grid.settings()');

		let settings = new Grid();

		if (config.columns) {
			config.columns.forEach( (c) => {

				grid.columns.forEach( (g) => {

					if (c.toLowerCase() == g.label.toLowerCase()) {
						settings.addColumn(g);
					}
				});
			});
		} else {
			grid.columns.forEach( (g) => {
				settings.addColumn(g);
			});
		}

		if (config.filters) {
			config.filters.forEach( (f) => {

				grid.filters.forEach( (g) => {

					if (f.toLowerCase() == g.field.label.toLowerCase()) {
						settings.addFilter(g);
					}
				});
			});
		} else {
			grid.filters.forEach( (g) => {
				settings.addFilter(g);
			});
		}

		settings.resource = grid.resource;
		settings.resourceParams = grid.resourceParams;

		return settings;
	}
}
