import { Injectable } from '@angular/core';
import {HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType, HttpHeaders} from '@angular/common/http';

import { CurrentTownService } from '../services/currentTown.service';
import { environment } from '../environments/environment';

@Injectable()
export class CityLogoResource {

	public ApiUrl: string = '/';
	public ServerWithApiUrl = environment.candygramHostName + '/';

	private actionUrl: string;

	url = 'gridsettings';

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService
	) {

	}

	post(data) {
		return new Promise( (resolve) => {

			this.getHeader().then( options => {

					this.http
					.post(this.ServerWithApiUrl + this.url, data, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then( data => {
						console.log(data);
						resolve(data);

					});

			});

		});
	}

	put(data) {
		return new Promise( (resolve) => {

			this.getHeader().then( (options: any) => {

				this.http.put(data.urlModifiers.id, data, { reportProgress: true, observe: 'events', headers: options.headers })
					.subscribe( (event) => {

						if ( event instanceof HttpResponse){
							setTimeout( () => {
								resolve(event);
							})
						} else if (event.constructor.name == 'HttpHeaderResponse') {
							setTimeout( () => {
								resolve(event);
							})
						}
					})

			});

		});
	}

	delete(url) {
		return new Promise( (resolve) => {

			this.getHeader().then( options => {

					this.http
					.delete(url, options)
					.toPromise()
					.then(this.extractData)
					.catch(this.handleError)
					.then( data => {
						resolve(data);
					})

			});

		});
	}

	private extractData(res: any) {

		let body = res;

		return body;
	}

	private handleError(error: any) {
		return error;
	}

	private getHeader() {
		var promise = new Promise( (resolve, reject) => {
			var headers = new HttpHeaders();

			var user = JSON.parse( localStorage.getItem('user') );
			
			var accessToken = JSON.parse( localStorage.getItem('accessToken') );

			this.currentTownService.get().then( town => {

				if (accessToken) {
					headers = headers.set('X-Token', accessToken.token);
				}				

				if (town) {
						headers = headers.set('X-City', town.slug);
					}


					var options = {
						headers: headers
					}

					resolve(options);
			});
				
		});	

			return promise;
	}
}