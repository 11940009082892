import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	templateUrl:  './billingHome.html'
})

export class BillingHomeComponent {
	address: any = {};

	constructor(
		private route: ActivatedRoute,
	) { }

}
