import { Pipe } from "@angular/core";

@Pipe({
	name: "mainMenuPipe",
	// pure: false
})
export class MainMenuPipe {
	transform(items, args): any {
		if (items) {
			return items.filter((item) => {
				//checking required town
				if (item.requires_town == true && args[0] == undefined) {
					return false;
				}

				if (!this.hasApplication(args[2], item)) {
					return false;
				}

				//checking permissions
				if (item.permission && args[1] != undefined) {
					var result = false;

					args[1].forEach((permission) => {
						if (item.permission == permission.permission) {
							result = true;

							return true;
						}
					});

					return result;
				}

				return true;
			});
		}
	}

	hasApplication(applications, item) {
		if (!item.application) {
			return true;
		}

		var found = false;

		applications.forEach((app) => {
			if (item.application == app.name) {
				found = true;
				return true;
			}
		});

		return found;
	}
}
