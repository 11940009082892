import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CurrentUserResource } from '../../resources/resources';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private currentUser: CurrentUserResource,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('This is client side error');
            errorMsg = `Error: ${error.error.message}`;
            throw error;
          } else {
            // console.log('This is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            if (error.status == 403) {
              // console.log(request);
              const reqHeaders = request.headers.getAll('x-city');
              // console.log(reqHeaders);
              this.toastrService.warning('Your session has expired. Please Log in again to continue.', 'Attention', { timeOut: 0, positionClass: 'toast-top-full-width' });

              sessionStorage.clear();
              localStorage.clear();
              this.currentUser.set(undefined);

              if (reqHeaders) {
                this.router.navigate(['/', reqHeaders[0], 'login']);
              } else {
                this.router.navigate(['/login']);
              }
            } else {
              throw error;
            }
          }
          // console.log(errorMsg);
          throw (errorMsg);
        })
      )
  }
}
