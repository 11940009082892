import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Field } from '../../../../../../..//dataTypes/dataTypes';

import {
  LicenseRenewalSettingsResource,
  BusinessLicenseListResource,
  LicenseTypeFeesResource,
  LicenseFeesResource,

  ResourceParams,
  City,
  Business,
  License,
  LicenseTypeFee,
  Fee
} from '@towncloud/thor-api';

import { FormService, CurrentTownService } from '../../../../../../../services/services';

import * as moment from 'moment';

@Component({
  selector: 'renewal-letter',
  templateUrl: './licenseRenewalLetter.html',
  styleUrls: ['licenseRenewalLetter.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicenseRenewalLetterComponent {
  @Input() preview;

  @Input()
  settings: any = {
    introduction: [],
    options: [],
    disclaimer: []
  };

  _license: License = new License();
  @Input() license: number;

  town: City = new City();
  business: Business = new Business();
  attributes: Array<any> = [];
  pin: Array<string> = [];
  renewalDate: string;
  amountDue: number = 0;

  constructor(
    private licenseRenewalSettingsResource: LicenseRenewalSettingsResource,
    private businessLicenseListResource: BusinessLicenseListResource,
    private currentTownService: CurrentTownService,
    private licenseTypeFeesResource: LicenseTypeFeesResource,
    private licenseFeesResource: LicenseFeesResource
  ) {
    this.currentTownService.get().then(town => {
      this.town = town;
    });
  }

  ngOnChanges() {
    if (this.preview === true) {
      this.business = new Business({
        name: 'Preview Business Name',
        mailingAddress: {
          address1: 'Address 1',
          address2: 'Address 2',
          city: 'City',
          state: {
            name: 'State'
          },
          zipCode: '12345',
          country: {
            name: 'United States of America'
          }
        },
        physicalAddress: {
          address1: 'Address 1',
          address2: 'Address 2',
          city: 'City',
          state: {
            name: 'State'
          },
          zipCode: '12345',
          country: {
            name: 'United States of America'
          }
        },
        pin: '654321'
      });

      this.attributes = [
        {
          fee: new Fee({
            name: 'Number of tables'
          }),
          quantity: 10
        },
        {
          fee: new Fee({
            name: 'Number of vending machines'
          }),
          quantity: 5
        }
      ];

      this.convertPinToArray();
    }

    if (this.settings) {
      if (this.settings.introduction && this.settings.introduction.length) {
        this.settings.introduction = this.settings.introduction.split('\n');
      }

      if (this.settings.options && this.settings.options.length) {
        this.settings.options = this.settings.options.split('\n');
      }

      if (this.settings.disclaimer && this.settings.disclaimer.length) {
        this.settings.disclaimer = this.settings.disclaimer.split('\n');
      }
    }

    if (this.license) {
      this.getSettings();

      this.getLicense().then((license: License) => {
        this._license = license;

        if (license.id) {
          this.renewalDate = moment(license.dateCreated).format(
            'MMMM DD, YYYY'
          );
        }

        this.business = new Business(this._license.business);

        this.convertPinToArray();

        this.getAttributes().then((attributes: Array<LicenseTypeFee>) => {
          this.getFees(attributes).then((fees: Array<any>) => {
            this.attributes = [];

            fees.forEach(fee => {
              this.amountDue += fee.quantity * fee.fee.amount;

              attributes.forEach(attribute => {
                if (fee.id === attribute.feeId) {
                  this.attributes.push(fee);
                }
              });
            });
          });
        });
      });
    }
  }

  getSettings() {
    var params = new ResourceParams();

    this.licenseRenewalSettingsResource.get(params).then(results => {
      this.settings.showAddress = results.printReturnAddress;
      this.settings.showSignature = results.includeSignatureLines;
      this.settings.introduction = results.renewalIntroduction
        ? results.renewalIntroduction.split('\n')
        : [];
      this.settings.options = results.renewalOptions
        ? results.renewalOptions.split('\n')
        : [];
      this.settings.disclaimer = results.renewalDisclaimer
        ? results.renewalDisclaimer.split('\n')
        : [];
    });
  }

  getLicense() {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        expand: [
          'type',
          'business',
          'business.physicalAddress.state',
          'business.physicalAddress.country',
          'business.mailingAddress.state',
          'business.mailingAddress.country'
        ],
        filters: [
          [
            {
              property: 'id',
              operator: 'eq',
              value: this.license
            }
          ]
        ]
      });

      this.businessLicenseListResource.get(params).then(licenses => {
        if (licenses.items.length > 0) {
          resolve(licenses.items[0]);
        } else {
          resolve(new License());
        }
      });
    });
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        $all: true,
        urlModifiers: {
          licenseTypeId: this._license.typeId
        },
        expand: ['fee'],
        filters: [
          [
            {
              property: 'trackAsAttribute',
              operator: 'eq',
              value: true
            }
          ]
        ]
      });

      this.licenseTypeFeesResource.get(params).then(fees => {
        resolve(fees.items);
      });
    });
  }

  getFees(attributes = []) {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        $all: true,
        urlModifiers: {
          businessId: this.business.id,
          licenseId: this._license.id
        },
        expand: ['fee']
      });

      this.licenseFeesResource.get(params).then(fees => {
        resolve(fees.items);
      });
    });
  }

  convertPinToArray() {
    this.pin = [];

    if (this.business.pin) {
      this.pin = this.business.pin.split('');
    }
  }
}
