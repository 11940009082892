
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) { }

	handleError(error: any): void {
		const toastr = this.injector.get(ToastrService);
		// console.log('@ # @ # @ global error handler - error @ # @ # @');

		if (error.message.includes('403')) {
			// token expired, clear localStorage which will trigger logout/login
			console.log('@ # @ # @ global error handler - 403 log out @ # @ # @');
			localStorage.clear();
			sessionStorage.clear();
		} else {
			// send error to toastr if not "TS undefined". need to add logging for that to enable developer followup
			if (!error.message.includes('undefined')) {
				toastr.error((error.message || 'Unknown error'), 'Unexpected Error', { timeOut: 0, positionClass: 'toast-top-full-width'});
			}
		}
		// log error to console
		console.error(error);
	}
}
