import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class CurrentUserService {
	static currentUserChange = new EventEmitter();

	constructor() {}

	emitChangeEvent(user) {
		CurrentUserService.currentUserChange.emit(user);
	}

	getChangeEmitter() {
		return CurrentUserService.currentUserChange;
	}
}
