import {
	Component,
	forwardRef,
	ViewEncapsulation,
	Input,
	Output,
	EventEmitter,
	HostListener,
	ViewChild,
	ElementRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComponentBase } from '../componentBase';

import { Field } from '../../../../dataTypes/dataTypes';

import {
	HttpClientModule,
	HttpClient,
	HttpRequest,
	HttpResponse,
	HttpEventType,
} from '@angular/common/http';

import { environment } from '../../../../environments/environment';

import { ResourceParams, DataService, User } from '@towncloud/thor-api';

import { CurrentTownService } from '../../../../services/services';

import * as moment from 'moment';

@Component({
	selector: 'field',
	templateUrl: './field.html',
	styleUrls: ['./field.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FieldComponent),
			multi: true,
		},
	],
})
export class FieldComponent extends ComponentBase {
	@ViewChild('field') field;

	@Input()
	disabled: boolean;

	@Input()
	settings: Field;

	showCalendar =false;
	showLookupModal = false;

	@Output() ngModelChange: EventEmitter<any> = new EventEmitter();

	status: string;
	fileList: any[] = [];
	percentUploaded = 0;

	searchResults: any[] = [];
	searchString: string;
	showSearchResults = false;
	calendarStyle: any = {};

	@ViewChild('file') fileElement: ElementRef;
	@ViewChild('calendar') calendarElement: ElementRef;

	constructor(
		private _elementRef: ElementRef,
		private http: HttpClient,
		private currentTownService: CurrentTownService
	) {
		super();
	}

	ngOnInit() {
		if (this.settings && this.settings.type === 'file') {
			let el = this._elementRef.nativeElement;

			el.addEventListener('dragover', this.handleDragOver.bind(this));

			el.addEventListener('dragleave', this.handleDragLeave.bind(this));

			el.addEventListener('drop', this.handleDrop.bind(this));
		}
	}

	ngDoCheck(prop) {
		if (this.settings) {
			if (this.settings.type === 'select') {
				this.setSelected();
			} else if (this.settings.type === 'number') {
				this.setPrecision();
			}
		}
	}

	ngOnDestroy() {
		let el = this._elementRef.nativeElement;

		el.removeEventListener('dragover', this.handleDragOver);

		el.removeEventListener('dragleave', this.handleDragLeave);

		el.removeEventListener('drop', this.handleDrop);
	}

	async handleDrop(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		await this.handleSelectedFiles(e.dataTransfer.files);

		this.valueChanged();
	}

	handleDragOver(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		this.status = 'hover';
	}

	handleDragLeave(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		this.status = undefined;
	}

	async handleSelectedFiles(files) {
		this.status = undefined;
		this.fileList = [];
		if (this.settings.preUpload !== false) {
			for (let i = 0; i < files.length; i++) {
				files[i].invalid = !this.allowedFileType(files[i]);
				this.fileList.push({ file: files[i] });
			}

			if (!this.settings.value) {
				this.settings.value = [];
			}
			for (let i = 0; i < this.fileList.length; i++) {
				if (!this.fileList[i].url && !this.fileList[i].file.invalid) {
					const result: any = await this.uploadFile(i);
					// this.settings.value.push(result.headers.get('location'));
					this.settings.value.push({file: files[i], content: result.headers.get('location')});
				} else {
					this.settings.value.push(files[i]);
				}
			}
			// this.fileList = [];
		} else {
			if (!this.settings.value) {
				this.settings.value = [];
			}
			for (let i = 0; i < files.length; i++) {
				files[i].invalid = !this.allowedFileType(files[i]);
				this.settings.value.push(files[i]);
			}
		}

		if (this.settings.maxCount) {
			this.settings.value.length = this.settings.maxCount;
		}
	}

	uploadFile(i) {
		return new Promise(async (resolve, reject) => {
			let file = this.fileList[i];

			var formData = new FormData();
			formData.append('file', file.file);

			file.percentUploaded = 0;
			const city = await this.currentTownService.get();

			let url = `${environment.candygramHostName}/files`;
			if (city) {
				url += `/${city.slug}`;
			}

			this.http
				.post(url, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event) => {
					if (event.type == HttpEventType.UploadProgress) {
						file.percentUploaded = Math.round(
							(100 * event.loaded) / event.total
						);
					} else if (event instanceof HttpResponse) {
						setTimeout(() => {
							resolve(event);
						});
					} else if (event.constructor.name == 'HttpHeaderResponse') {
						setTimeout(() => {
							resolve(event);
						});
					}
				});
		});
	}

	removeFile(i) {
		this.settings.value.splice(i, 1);
	}

	evaluateOption(option, property) {
		if (property) {
			return eval('option.' + property);
		}

		return eval('option');
	}

	toggleCalendar = function (event) {
		this.showCalendar = !this.showCalendar;

		if (this.showCalendar) {
			// console.log(event);
			const iconElement = event.target as HTMLElement;
			// console.log(iconElement);
			const inputElement = iconElement.closest('.dateWrapper').querySelector('input[type="date"]');
			// console.log(inputElement);
			this.adjustCalendarPosition(inputElement);
		}
	};
	adjustCalendarPosition(inputElement: HTMLElement) {
		const inputRect = inputElement.getBoundingClientRect();
		const calendarWidth = 250; // Assume a fixed width of the calendar component
		const calendarHeight = 300; // Assume a fixed height for the calendar component
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
	
		// Find the offset parent to calculate positions relative to it
		const offsetParent = inputElement.offsetParent as HTMLElement;
		const offsetParentRect = offsetParent.getBoundingClientRect();
		
		let chosenLeftPosition;
		if ((inputRect.left + calendarWidth - offsetParentRect.left) <= viewportWidth) {
			chosenLeftPosition = inputRect.left - offsetParentRect.left;
		} else if ((inputRect.right - calendarWidth - offsetParentRect.left) >= 0) {
			chosenLeftPosition = inputRect.right - calendarWidth - offsetParentRect.left;
		} else {
			chosenLeftPosition = viewportWidth - calendarWidth - offsetParentRect.left;
		}
	
		chosenLeftPosition = Math.max(chosenLeftPosition, 0);
	
		const topPositionAdjustment = 30; // Adjust this value as needed to reduce the gap
		let topPosition = inputRect.bottom - offsetParentRect.top - topPositionAdjustment;
	
		// Calculate the bottom position of the calendar
		let calendarBottomPosition = topPosition + calendarHeight;
	
		// If the calendar is clipped at the bottom, adjust the top position to move the calendar above the input field
		if (calendarBottomPosition > viewportHeight) {
			topPosition = inputRect.top - offsetParentRect.top - calendarHeight; // Move above the input field
		}
	
		// Ensure the calendar is not clipped at the top of the viewport
		topPosition = Math.max(topPosition, 0);
	
		const calendarStyle = {
			position: 'absolute',
			top: `${topPosition}px`, // Adjusted to ensure the calendar is positioned correctly
			left: `${chosenLeftPosition}px`
		};
	
		// Apply the style to the calendar component
		this.calendarStyle = calendarStyle;
	}

	calendarChanged(event) {
		if (event) {
			if (this.showCalendar == true) {
				this.ngModelChange.emit(event);
				this.toggleCalendar(event);
			}
		}
	}

	valueChanged() {
		if (this.settings.value != undefined) {
			if (this.settings.type == 'input') {
				if (this.settings.value.trim().length == 0) {
					this.settings.value = undefined;
				}
			}
		}

		if (this.settings.type == 'number') {
			this.setPrecision();
		}

		this.ngModelChange.emit(this.settings.value);
	}

	setSelected() {
		this.settings.options.forEach((option, index) => {
			if (JSON.stringify(this.settings.value) == JSON.stringify(option)) {
				this.settings.value = option;

				return;
			}
		});
	}

	setPrecision() {
		if (this.settings.value != undefined) {
			if (this.settings.precision != undefined) {
				if (typeof this.settings.value === 'string') {
					if (!isNaN(parseFloat(this.settings.value))) {
						this.settings.value = parseFloat(this.settings.value);
					}
				}

				this.settings.value = parseFloat(
					this.settings.value.toFixed(this.settings.precision)
				);
			}
		}
	}

	toggleShowLookupModal() {
		this.showLookupModal = !this.showLookupModal;
	}

	selectLookupRow(e) {
		this.settings.value = e.row;
		this.ngModelChange.emit(e.row);

		this.showLookupModal = false;
	}

	incrament(number) {
		if (!isNaN(this.settings.value)) {
			this.settings.value += number;
		} else {
			this.settings.value = number;
		}

		if (this.settings.max != undefined) {
			if (this.settings.max < this.settings.value) {
				this.settings.value = this.settings.max;
			}
		}

		if (this.settings.min != undefined) {
			if (this.settings.min > this.settings.value) {
				this.settings.value = this.settings.min;
			}
		}
		this.ngModelChange.emit(number);
	}

	lookupLabel(): string {
		if (this.settings.value) {
			if (this.settings.lookupLabel.constructor.name === 'Function') {
				return this.settings.lookupLabel();
			} else {
				return this.settings.value[this.settings.lookupLabel];
			}
		}
	}

	search() {
		if (this.settings.resource) {
			var params = new ResourceParams({
				offset: 0,
				limit: 5,
				// $bold: true,
			});

			if (this.settings.filters) {
				let filters = [];
				this.settings.filters.forEach((f) => {
					let filterArray = f.split(':');
					filters.push({
						property: filterArray[0],
						operator: filterArray[1],
						value: this.searchString ? this.searchString : '',
					});
				});

				params.filters = [filters];
			} else if (this.settings.resourceParams.filters) {
				params.filters = [];

				this.settings.resourceParams.filters.forEach((filter) => {
					let filters = [];

					filter.forEach((f) => {
						let filterCopy = { ...f };
						if (filterCopy.value == '$value') {
							filterCopy.value = this.searchString ? this.searchString : '';
						}
						filters.push(filterCopy);
					});

					params.filters.push(filters);
				});
			}

			if (this.settings.resourceParams) {
				params.sort = this.settings.resourceParams.sort;
				params.expand = this.settings.resourceParams.expand;
				params.urlModifiers = this.settings.resourceParams.urlModifiers;
			}

			this.settings.resource
				.get(params)
				.then((data) => {
					this.showSearchResults = true;

					data.items.forEach((item) => {
						if (this.settings.optionLabel) {
							let label = this.settings.optionLabel;

							var regex = /(\${).+?(})/g;
							var matches = ('test ' + this.settings.optionLabel).match(regex);

							matches.forEach((m) => {
								let value = eval(
									`item.${m.replace('${', '').replace('}', '')}`
								);
								label = label.replace(m, value ? value : '');
							});

							item.$label = label;
						} else {
							item.$label = item.name;
						}

						if (this.searchString)
							item.$label = this._getBoldedString(
								item.$label,
								this.searchString
							);
					});

					this.searchResults = data.items;
				})
				.catch((e) => {
					console.log(e);
					// if backend returns a 403 (invalid/expired token detected) push the error which will result in user being logged out
					if (e.status == 403) {
						throw e;
					}
				});
		}
	}

	private _getBoldedString(string, substring) {
		var searchStrLen = substring.length;
		if (searchStrLen == 0) {
			return [];
		}
		var startIndex = 0,
			index,
			indices = [];

		var str = string.toLowerCase();
		var searchStr = substring.toLowerCase();

		while ((index = str.indexOf(searchStr, startIndex)) > -1) {
			indices.push(index);
			startIndex = index + searchStrLen;
		}

		var subStrings = [];

		for (var i = 0; i < indices.length; i++) {
			subStrings.push(
				string.substring(indices[i], indices[i] + substring.length)
			);
		}

		if (indices.length > 0) {
			for (var i = indices.length - 1; i >= 0; i--) {
				if (indices[i] != 0) {
					string =
						string.slice(0, indices[i]) +
						'<b>' +
						string.slice(indices[i], indices[i] + substring.length) +
						'</b>' +
						string.slice(indices[i] + substring.length, string.length);
				} else {
					string =
						'<b>' +
						string.slice(indices[i], indices[i] + substring.length) +
						'</b>' +
						string.slice(indices[i] + substring.length, string.length);
				}
			}
		}

		return string;
	}

	selectItem(item) {
		this.settings.value = item;

		this.showSearchResults = false;
		this.searchResults = [];
		this.searchString = undefined;

		this.valueChanged();
	}

	browseFiles() {
		this.fileElement.nativeElement.click();
	}

	async test(e) {
		await this.handleSelectedFiles(e.target.files);
		this.valueChanged();
	}

	allowedFileType(file) {
		let allowedExtensions = [];
		if (this.settings.fileTypes) {
			allowedExtensions = this.settings.fileTypes;
		} else{
			allowedExtensions = [
				'csv',
				'doc',
				'docx',
				'dwg',
				'gif',
				'htm',
				'html',
				'jpeg',
				'jpg',
				'mid',
				'midi',
				'png',
				'pdf',
				'ppt',
				'pptx',
				'tif',
				'tiff',
				'txt',
				'xls',
				'xlsx',
				'xml',
				'3gp',
				'3g2',
			];
		}	

		const extension = file.name.split('.').pop();
		let valid = false;

		allowedExtensions.forEach((type) => {
			if (extension === type) {
				valid = true;
				return;
			}
		});
		return valid;
	}

	setFocus() {
		this.field.nativeElement.focus();
	}
}
