import {
  Component,
  Input,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';

// import { NewBuildingPermitApplicationComponent } from "../../permits/views/newBuilding/newBuildingPermit.application.component";

@Component({
  selector: 'dynamicHTML',
  template: '<div #dynamicTarget></div>'
})
export class DynamicHTMLComponent implements OnInit, OnDestroy {
  // We'll need to keep track of our injected component to manage it correctly
  // private componentReference: ComponentRef<any>;

  @Input() myComponent;

  // Component input
  @ViewChild('dynamicTarget', { read: ViewContainerRef })
  dynamicTarget: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private element: ElementRef
  ) {}

  ngOnInit() {
    const component = this.resolver.resolveComponentFactory(this.myComponent);

    setTimeout(() => {
      this.dynamicTarget.createComponent(component);
      // this.element.createComponent(component);
    });

    // Create our component now we're initialised
    // let componentFactory = this.resolver.resolveComponentFactory(this.MyComponent);
    // this.componentReference = this.dynamicTarget.createComponent(componentFactory);
  }

  ngOnDestroy() {
    // If we have a component, make sure we destroy it when we lose our owner
    // if (this.myComponent) {
    //     this.myComponent.destroy();
    // }
  }
}
