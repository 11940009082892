import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'my-table',
  templateUrl: './table.template.html',
  styleUrls: ['./table.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TableComponent { 

	@Input() 
	settings;

	@Input()
	selectMethod;

	@Output() clickLine: EventEmitter<any> = new EventEmitter();

	constructor(){
	}

	hasFilters(){
		var response = false;
		this.settings.columns.forEach((column) => {
			if(column.filterable){
				response = true;
				return false;
			}
		})

		return response;
	}

	selectLine(line){
		this.clickLine.emit({ value: line });
	}

	evaluateCell(row, cell){
		return eval('row.'+cell.field);
	}
}